import React from "react";
import { Component } from "react";
// import axios from "axios";
import AllCategories from "./AllCategories";
import CreateCategory from "./CreateCategory";
import ModifyCategory from "./ModifyCategory";

class Categories extends Component {
  state = {
    pageView: 0,
    modifyCategory: false,
    selectedCategory: {},
  };

  changeModifyCategory() {
    if (this.state.modifyCategory === false)
      this.setState({
        modifyCategory: true,
      });
    else
      this.setState({
        modifyCategory: false,
      });
  }

  changePageView() {
    if (this.state.pageView == 0)
      this.setState({
        pageView: 1,
      });
    else
      this.setState({
        pageView: 0,
      });
  }

  setSelectedCategory(category_id) {
    this.setState({
      selectedCategory: category_id
    })
  }
  render() {
    return (
      <div className="categories-page">
        {
          this.state.pageView === 0 ?
            <AllCategories categories={this.props.categories}
              changeCategories={this.props.changeCategories}
              changePageView={this.changePageView.bind(this)}
              changeModifyCategory={this.changeModifyCategory.bind(this)}
              modifyCategory={this.state.modifyCategory}
              setSelectedCategory={this.setSelectedCategory.bind(this)} /> :
            this.state.modifyCategory == true ?
              <ModifyCategory selectedCategory={this.state.selectedCategory}
                changePageView={this.changePageView.bind(this)}
                changeModifyCategory={this.changeModifyCategory.bind(this)}
                categories={this.props.categories}
                changeCategories={this.props.changeCategories} /> :
              <CreateCategory
                categories={this.props.categories}
                changeCategories={this.props.changeCategories}
                changePageView={this.changePageView.bind(this)}
                changeModifyCategory={this.changeModifyCategory.bind(this)}
                modifyCategory={this.state.modifyCategory} />
        }
      </div>
    );
  }
}

export default Categories;
