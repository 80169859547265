import React, { useState, useEffect } from "react";
import AllItems from "./AllItems";
import CreateButcherItem from "./CreateButcherItem";
import CreateRestaurantMenuItem from "./CreateRestaurantMenuItem";
import CreateRestaurantPlatItem from "./CreateRestaurantPlatItem";
import EditButcherItem from "./EditButcherItem";
import EditRestaurantMenuItem from "./EditRestaurantMenuItem";
import EditRestaurantPlatItem from "./EditRestaurantPlatItem";

const Items = (props) => {
  const [pageView, setPageView] = useState(0);
  const [itemId, setItemId] = useState("");
  const [item, setItem] = useState({});

  useEffect(async ()=>{
    await setPageView(0);
  }, [props.categoryId])
  return (
    <div className="items-page">
      {console.log("PROPS CAT TYPE", props, props.category.type)}
      {pageView === 0 ? (
        <AllItems
          category={props.category}
          setPageView={setPageView}
          categoryId={props.categoryId}
          setItemId={setItemId}
          setItem={setItem}
        />
      ) : pageView === 1 ? (
        props.category.type === "butcher" ? (
          <CreateButcherItem
            setPageView={setPageView}
            categoryId={props.categoryId}
          />
        ) : props.category.type === "menu" ? (
          <CreateRestaurantMenuItem
            category={props.category}
            setPageView={setPageView}
            categoryId={props.categoryId}
            setItemId={setItemId}
            setItem={setItem}
          />
        ) : (
          <CreateRestaurantPlatItem
            category={props.category}
            setPageView={setPageView}
            categoryId={props.categoryId}
            setItemId={setItemId}
            setItem={setItem}
          />
        )
      ) : props.category.type === "butcher" ? (
        <EditButcherItem
          setPageView={setPageView}
          pageView={pageView}
          categoryId={props.categoryId}
          itemId={itemId}
          setItem={setItem}
          item={item}
        />
      ) : props.category.type === "menu" ? (
        <EditRestaurantMenuItem
          category={props.category}
          setPageView={setPageView}
          categoryId={props.categoryId}
          setItemId={setItemId}
          itemId={itemId}
          item={item}
          setItem={setItem}
        />
      ) : (
        <EditRestaurantPlatItem
          category={props.category}
          setPageView={setPageView}
          categoryId={props.categoryId}
          setItemId={setItemId}
          item={item}
          itemId={itemId}
          setItem={setItem}
        />
      )
      }
    </div>
  );
};

export default Items;
