import axios from 'axios'
import React, { Component } from 'react'
import { Input, Button, message, Popconfirm } from 'antd';
import PartnerDetails from './PartnerDetails';

class AdminDashboard extends Component {

  state = {
    page: 1,
    partners: [],
    partner_type: "",
    search: "",
    dashboard_view: true,
    selected_partner: ""
  }

  constructor(props) {
    axios.defaults.headers.common['Authorization'] = 'BEARER ' + localStorage.getItem("mooze_admin_jwt");
    super(props)
    let url = process.env.REACT_APP_HOST + process.env.REACT_APP_GET_PARTNER_LIST_API
    axios
      .get(url, {params: {page_number: 1}})
      .catch(function (error) {
        message.error("Error", 5)
      })
      .then(async (res) => {
        let partner_type = Object.keys(res?.data).length !== 0 ? Object.keys(res?.data)[0] : "none"
        await this.setState({partners: res.data, partner_type: partner_type})
      })
  }


  changeView() {
    if (this.state.dashboard_view === true)
      this.setState({dashboard_view: false})
    else
      this.setState({dashboard_view: true})
  }

  showUser(user) {
    this.setState({selected_partner: user})
    this.changeView();
  }

  selectPartnerType(partner_type) {
    this.setState({partner_type: partner_type})
  }

  deletePartner(type, partner) {
    let url = process.env.REACT_APP_HOST + process.env.REACT_APP_DELETE_PARTNER_API
    let values = {
      partner_id: partner._id
    }
    var user_type_id = ""
    switch (type) {
      case 'Restaurants':
        user_type_id = "2"
        break;
      case 'Boucheries':
        user_type_id = "3"
        break;
    }
    let axiosHeaders = {
      headers: {
        'user_type_id': user_type_id,
      }
    }
    axios.post(url, values, axiosHeaders)
      .catch(function (error) {
        message.error("Error While deleting partner", 5);
        window.location.reload()
      })
      .then((result) => {
        message.success("Partner removed", 5)
      })
    let new_partners = JSON.parse(JSON.stringify(this.state.partners))
    if (new_partners[type].length === 1) {
      delete new_partners[type];
      if (Object.keys(new_partners).length === 0) {
        this.setState({
          partners: new_partners,
          partner_type: "none"
        })
      } else {
        this.setState({
          partners: new_partners,
          partner_type: Object.keys(new_partners)[0]
        })
      }
    } else {
      var new_partner_array = new_partners[type].filter(new_partner => partner._id != new_partner._id)
      new_partners[type] = new_partner_array
      this.setState({
        partners: new_partners,
      })
    }
  }

  searchBar(search) {
    this.setState({search: search.target.value})
  }

  nextPageReq() {
    axios.defaults.headers.common['Authorization'] = 'BEARER ' + localStorage.getItem("mooze_admin_jwt");
    let url = process.env.REACT_APP_HOST + process.env.REACT_APP_GET_PARTNER_LIST_API
    this.setState({page: this.state.page + 1})
    axios
      .get(url, {params: {page_number: this.state.page + 1}})
      .catch(function (error) {
        message.error("Error", 5)
      })
      .then(async (res) => {
        console.log(res, 'this is res');
        let partner_type = Object.keys(res?.data).length !== 0 ? Object.keys(res?.data)[0] : "none"
        await this.setState({partners: res?.data, partner_type: partner_type})
      });
  }

  prevPageReq() {
    axios.defaults.headers.common['Authorization'] = 'BEARER ' + localStorage.getItem("mooze_admin_jwt");
    let url = process.env.REACT_APP_HOST + process.env.REACT_APP_GET_PARTNER_LIST_API
    this.setState({page: this.state.page - 1})
    axios
      .get(url, {params: {page_number: this.state.page - 1}})
      .catch(function (error) {
        message.error("Error", 5)
      })
      .then(async (res) => {
        var partner_type = Object.keys(res?.data).length !== 0 ? Object.keys(res?.data)[0] : "none"
        await this.setState({partners: res?.data, partner_type: partner_type})
      })
  }

  render() {
    console.log(this.state, 'this is state');
    if (this.state.partner_type === "") return null
    return (
      <div className="admin-dashboard-page">
        {this.state.dashboard_view === true ?
          <div className="admin-dashboard">
            <h2 className="admin-dashboard-subtitle">Supprimer ou modifier les informations de nos partenaires</h2>
            <h1 className="admin-dashboard-title">Nos partenaires &nbsp;
              <Input className="admin-dashboard-search" placeholder="Rechercher" onChange={this.searchBar.bind(this)}
                     disabled={this.state.partner_type === "none" ? true : false}></Input>
            </h1>
            <div className="admin-dashboard-partner-type-selection">
              {Object.keys(this.state.partners).map(partner_type => (
                <div
                  className={this.state.partner_type === partner_type ? "admin-dashboard-partner-type-selected" : "admin-dashboard-partner-type"}
                  key={partner_type}
                  onClick={() => this.selectPartnerType(partner_type)}>
                  {partner_type}
                </div>
              ))}
            </div>
            <br></br>
            {this.state.partner_type !== "none" ?
              <div className="admin-dashboard-partners-list">
                {this.state.partners[this.state.partner_type].map(partner => (
                  <div key={partner.establishment_name}>
                    {partner.establishment_name.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0 ?
                      <div className="admin-dashboard-partner-item">
                        <div
                          className="admin-dashboard-partner-name">
                                                    <span>
                                                        &nbsp;&nbsp;{partner.establishment_name}
                                                    </span>
                        </div>
                        <div>
                          <Button
                            onClick={() => this.showUser(partner._id)}
                            className="admin-dashboard-modify-partner">
                            Modify
                          </Button>&nbsp;&nbsp;
                          <Popconfirm placement="right" title="Delete this partner?"
                                      onConfirm={() => {
                                        this.deletePartner(this.state.partner_type, partner)
                                      }}>
                            <Button className="admin-dashboard-delete-partner">
                              Supprimer
                            </Button>
                          </Popconfirm>
                        </div>
                      </div>
                      : null}
                  </div>
                ))}
              </div>
              :
              <h2 className="no-partner">You have no active partners</h2>
            }
            <div>
              <Button disabled={this.state.page === 1} type="primary" onClick={this.prevPageReq.bind(this)} style={{marginRight: 60}}>Prev page</Button>
              <Button disabled={this.state.page === this.state?.partners.pagination.page_count} type="primary" onClick={this.nextPageReq.bind(this)}>Next page</Button>
            </div>
          </div>
          : <PartnerDetails user={this.state.selected_partner} changeView={this.changeView.bind(this)}
                            partner_type={this.state.partner_type} />}
      </div>
    )
  }
}

export default AdminDashboard;
