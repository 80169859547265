import React, { Component } from 'react'
import { Form, Button, message, Input, Select, Upload } from 'antd'
import ImgCrop from "antd-img-crop";
import axios from 'axios';
const { Option } = Select;

class ReferenceData extends Component {

    state = {
        category: "",
        subcategory: "",
        itemPhoto: [],
    }

    formRef = React.createRef();

    addData(values) {
        if (this.state.itemPhoto.length === 0) {
            message.error("Please enter image of item!", 5);
            return;
        }
        else {
            message.loading("Please wait for item upload!")
            const formData = new FormData();
            formData.append("file", this.state.itemPhoto[0].originFileObj);
            formData.append("name", values.item_name);
            formData.append("category", values.category);
            if (values.category === "ingredients")
                formData.append("subcategory", values.subcategory_ingredient);
            else if (values.category === "drinks")
                formData.append("subcategory", values.subcategory_drink);
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            let url = process.env.REACT_APP_HOST + process.env.REACT_APP_ADD_REFERENCE_DATA_API
            axios
                .post(url, formData, config)
                .catch(function (error) {
                    message.error("Error while adding item", 5);
                })
                .then(async (res) => {
                    message.success("Item added!", 5)
                    this.setState({ itemPhoto: [] })
                    this.formRef.current.resetFields();
                })
        }
        console.log("data submitted", values)
    }
    async setCategory(value) {
        await this.setState({
            category: value,
            subcategory: ""
        })
        console.log(this.state)
    }

    async setSubcategory(value) {
        await this.setState({
            subcategory: value
        })
        console.log(value)
    }

    beforeUpload(file) {
        console.log("CISJKCNSCJKS", file)
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    }

    dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }

    onChangeItem = ({ file }) => {
        console.log('FIL', file);
        if (file.status !== "uploading") {
            if (this.state.itemPhoto.length !== 0) {
                this.setState({ itemPhoto: [] })
            } else {
                this.setState({ itemPhoto: [file] });
            }
        };
    }

    render() {
        return (
            <div className="add-reference-data">
                <div className="add-reference-data-title">
                <h1 >
                    Add reference data
                </h1>
                </div>
                <Form
                    ref={this.formRef}
                    name="add-reference-data-form"
                    className="add-reference-data-form"
                    onFinish={this.addData.bind(this)}>
                    <ImgCrop rotate
                        beforeCrop={this.beforeUpload}
                    >
                        <Upload
                            customRequest={this.dummyRequest}
                            listType="picture-card"
                            fileList={this.state.itemPhoto}

                            onChange={this.onChangeItem}
                            onPreview={this.onPreview}
                        >
                            {this.state.itemPhoto && this.state.itemPhoto.length < 1 && "+ Add Item Photo"}
                        </Upload>
                    </ImgCrop>
                    <Form.Item
                        name="item_name" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                        <Input placeholder="Nom de l'article" />
                    </Form.Item>
                    <Form.Item
                        name="category" rules={[{ required: true, message: "Ceci est nécessaire" }]}
                    >
                        <Select placeholder="Type d'article" onChange={this.setCategory.bind(this)}>
                            <Option value="sauces">Sauces</Option>
                            <Option value="ingredients">Ingrédients</Option>
                            <Option value="drinks">Boissons</Option>
                            <Option value="category_icons">Categorie</Option>
                            <Option value="size_icons">Size</Option>
                        </Select>
                    </Form.Item>
                    {this.state.category === "drinks" ?

                        <Form.Item
                            name="subcategory_drink" rules={[{ required: true, message: "Ceci est nécessaire" }]}
                        >
                            <Select placeholder="Type de boissons" onChange={this.setSubcategory.bind(this)}>
                                <Option value="canned_soda">Canettes Soda</Option>
                                <Option value="bottled_soda">Bouteilles Soda</Option>
                                <Option value="water">Eaux</Option>
                                <Option value="juice">Jus</Option>
                                <Option value="other">Autres Boissons</Option>
                            </Select>
                        </Form.Item>
                        : null
                    }
                    {this.state.category === "ingredients" ?

                        <Form.Item
                            name="subcategory_ingredient" rules={[{ required: true, message: "Ceci est nécessaire" }]}
                        >
                            <Select placeholder="Type d'ingrédient" onChange={this.setSubcategory.bind(this)}>
                                <Option value="meat">Viandes</Option>
                                <Option value="fish">Poisson fruits de mer</Option>
                                <Option value="vegetables">Légumes</Option>
                                <Option value="fruits">Fruits</Option>
                                <Option value="spices">Epices</Option>
                                <Option value="other">Autres ingrédients</Option>
                            </Select>
                        </Form.Item>
                        : null
                    }
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="reference-data-submit">
                            Ajouter
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }

}

export default ReferenceData