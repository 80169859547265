import React, {useState} from 'react';

import { Form, Input, Button, Image, message } from 'antd';

import MFBLogo from '../assets/moozeforbusiness.png';

import { Anchor } from 'antd';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import ForgotPasswordForm from '../Login/ForgotPasswordForm';

const { Link } = Anchor;

const AdminLogin = (props) => {

    message.config({
        maxCount: 1
    })
  
    const history = useHistory();

    const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);

    const callForgotPassword = () => {
        setShowForgotPasswordForm(true)
    }

    const loadLocalStorage = (data)=> {

        localStorage.setItem('mooze_admin_jwt', data.accessToken);
        localStorage.setItem('mooze_admin_user', data.id);
        localStorage.setItem('mooze_admin_user_type_id', data.user_type_id);
        history.push("/adminArea")
    }


    const  onFinish = (values) => {
        message.loading('Please wait...', 2);
         let url = process.env.REACT_APP_HOST + process.env.REACT_APP_PARTNER_LOGIN;
         let user_type_id = 1;
         console.log(values);
        
         let axiosHeaders = {
             headers: {
                 'user_type_id': user_type_id,
             }
         }
         axios.post(url,values, axiosHeaders).then((res)=> {
             message.success('Login successful!', 3);
             loadLocalStorage(res.data);
 
          }).catch(function (error) {
             // if (JSON.stringify(error).indexOf("401") >= 0);
                 message.error('Invalid Credentials!', 3)
         })
     };
    return (
        
        
        <div className="login__loginForm">
            {
                showForgotPasswordForm === true ? <ForgotPasswordForm userTypeId={1}/> : 
                (
                    <div className="login__loginForm__form">
                    <div className="login__loginForm__homelogo">
                        <Image
                            preview={false}
                            width={450}
                            src={MFBLogo}
                        />
                    </div>
    
                    <Form
                        name="partner_login"
                        className="partner_loginForm"
                        // initialValues={ }
                        onFinish={onFinish.bind(this)}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Ceci est nécessaire",
                                }
                            ]}
                        >
                            <Input
                                type="email"
                                placeholder="Email"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Ceci est nécessaire",
                                }
                            ]}
                        >
                            <Input type="password" placeholder="Mot de Passe" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="partner_loginForm_button">
                                Connexion
                        </Button>
                        </Form.Item>
                    </Form>
                    <div className="login__forgotPassword">
                        <Anchor affix={false} onClick={callForgotPassword}>
                            <Link href="#" title="Mot de Passe oublié?" />
                        </Anchor>
                    </div>
                </div>
           
                )
            }
            </div>
    );

}

export default AdminLogin;