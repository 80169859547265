import React, { useEffect, useState } from "react";
import { Form, Input, message, Button } from "antd";
import axios from "axios";
import ImgCrop from "antd-img-crop";
import PartnerOpeningHours from "./PartnerOpeningHours";
import StripeButton from "./Stripe";
import { QRCode } from 'react-qrcode-logo';
import logoImage from '../assets/logo.png'
import EstimationTime from "./common/EstimationTime";
import { defaultPreparationTime } from "../constants/options";
import { Upload } from 'antd'
import Checkbox from '../components/Checkbox/Checkbox';
import useGetTags from "./useGetTags";
import styled from "styled-components";
import { DeleteOutlined } from "@ant-design/icons";
import { EyeOutlined } from "@ant-design/icons";
import Dropdown from "../components/Dropdown/Dropdown";

const hoursInit = {
  Lundi: [],
  Mardi: [],
  Mercredi: [],
  Jeudi: [],
  Vendredi: [],
  Samedi: [],
  Dimanche: [],
};

const RemoveIcon = styled(DeleteOutlined)`
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-left: 10%;
  transform: translate(-50%, -50%);
  position: absolute;
  color: rgba(255, 255, 255, 0.8);
  font-size: 30px;
`
const PreviewIcon = styled(EyeOutlined)`
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-left: -10%;
  transform: translate(-50%, -50%);
  position: absolute;
  color: rgba(255, 255, 255, 0.8);
  font-size: 30px;
`

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
`

const PartnerAccount = () => {
  const timeFormat = "HH:mm";
  const [logo, setLogo] = useState();
  const [currencies, setCurrencies] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedCurrency, SetSelectedCurrency] = useState({});
  const [homePagePhoto, setHomePagePhoto] = useState([]);
  const [images, setImages] = useState([]);
  const [convertedImages, setConvertedImages] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [partnerDetails, setPartnerDetails] = useState({});
  const [openingHours, setOpeningHours] = useState(hoursInit);
  const [rushHours, setRushHours] = useState(hoursInit);
  const [qrCodeUrl, setQRCodeUrl] = useState(0)
  const [estimationTime, setEstimationTime] = useState(defaultPreparationTime);
  const [tags] = useGetTags();
  const [selectedTags, setSelectedTags] = useState([]);
  const [itemPhoto, setItemPhoto] = useState([]);
  const [showDelete, setShowDelete] = useState(false)
  const [loading, setLoading] = useState('');



  useEffect(() => {
    if (currencies && currencies.length) {
      const result = currencies.map(currency => {
        return {
          ...currency,
          title: `${currency.name} ${currency.symbol}`
        };
      });
      setDropdownOptions(result);
    }
  }, [currencies])


  useEffect(() => {
    let url =
      process.env.REACT_APP_HOST + "/getCurrencies"
    axios
      .get(url)
      .then(async (res) => {
        setCurrencies(res.data.currencies);
      });
  }, []);


  useEffect(() => {
    if (partnerDetails?.images?.length === 0) {
      setImages([null]);
    } else {
      const result = partnerDetails?.images?.map(item => {
        return {url: item.url, id: item._id};
      });
      if (result && result.length > 0) {
        setImages([...result, null]);
      }
    }
    if (partnerDetails.logo) {
      setLogo(partnerDetails.logo);
    }

    if (partnerDetails.tags && partnerDetails.tags.length) {
      const result = partnerDetails.tags?.map(item => item._id);
      setSelectedTags(result);
    }


  }, [partnerDetails])

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };


  const onChangeLogo = ({file}) => {
    if (file.status !== "uploading") {
      if (logo.length !== 0) {
        setLogo([]);
      } else {
        setLogo([file]);
      }
    }
  };

  const onChangePhoto = ({file}) => {
    if (file.status !== "uploading") {
      if (homePagePhoto.length !== 0) {
        setHomePagePhoto([]);
      } else {
        setHomePagePhoto([file]);
      }
    }
  };

  const getPartnerDetails = () => {
    var error_flag = 0;
    let url =
      process.env.REACT_APP_HOST +
      process.env.REACT_APP_GET_PARTNER_DETAILS_API;
    let axiosData = {
      params: {
        partner_id: localStorage.getItem("mooze_admin_user"),
      },
      headers: {
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };
    axios
      .get(url, axiosData)
      .catch(function (e) {
        message.error("Erreur lors de l'obtention des détails du compte", 5);
        error_flag = 1;
      })
      .then(async (res) => {
        console.log('partner details', res.data._id);

        if (!error_flag) {
          let _type = ((res.data.partner_type === "restaurant") ? '2' : '3')
          let _url = "https://mooze.fr/download/" + res.data._id + "/" + _type;
          if (res.data.opening_hours)
            await setOpeningHours(res.data.opening_hours);
          if (res.data.rush_hours)
            await setRushHours(res.data.rush_hours);
          await setQRCodeUrl(_url)
          await setPartnerDetails(res.data);
          if (res.data.homepage_photo) {
            setHomePagePhoto([
              {
                id: 1,
                url: res.data.homepage_photo,
              },
            ]);
          }
          // if (res.data.prep_hours) await setPrepHours(res.data.prep_hours);
          if (res.data.estimation_time) setEstimationTime(res.data.estimation_time);
        }
      });
  };

  useEffect(() => {
    getPartnerDetails();
  }, [])


  const updateDetails = (values) => {
    console.log(logo , 'logo')
    if (
      !estimationTime /*&& prepHours === 0*/ &&
      localStorage.getItem("mooze_admin_user_type_id") === "3"
    ) {
      message.error("Sélectionnez les heures de préparation", 5);
      return;
    } else {
      message.loading(
        "Veuillez attendre le téléchargement des détails du compte!",
        3
      );
      const formData = new FormData();
      formData.append("user_id", localStorage.getItem("mooze_admin_user"));
      formData.append("admin_name", values.admin_name);
      formData.append("currency", selectedCurrency.name);
      formData.append("symbol", selectedCurrency.symbol);
      formData.append("city", values.city);
      formData.append("tags", selectedTags);
      formData.append("email_admin", values.email_admin);
      formData.append("email_pro", values.email_pro);
      formData.append("establishment_name", values.establishment_name);
      formData.append("latitude", values.latitude);
      formData.append("longitude", values.longitude);
      formData.append("street", values.street);
      formData.append("website_link", values.website_link);
      formData.append("instagram_link", values.instagram_link);
      formData.append("deliveryapp_link", values.deliveryapp_link);
      formData.append("zipcode", values.zipcode);
      formData.append("opening_hours", JSON.stringify(openingHours));
      formData.append("rush_hours", JSON.stringify(rushHours));
      if (localStorage.getItem("mooze_admin_user_type_id") === "3") {
        // formData.append("prep_hours", prepHours);
        formData.append("estimation_time", JSON.stringify(estimationTime));
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
        },
      };
      var err_flag = 0;
      let url =
        process.env.REACT_APP_HOST +
        process.env.REACT_APP_UPDATE_PARTNER_ACCOUNT_API;
      axios
        .post(url, formData, config)
        .catch(function (error) {
          message.error("Error while adding item", 5);
          err_flag = 1;
        })
        .then(async (res) => {
          console.log(res, 'this is res');
          if (!err_flag) message.success("Détails du compte mis à jour!", 5);
          getPartnerDetails();
        });
    }
  };

  if (Object.keys(partnerDetails).length === 0) return null;

  const getOpenPeriods = (openingHours, day) => {
    const periods = [];
    [0, 1].forEach(index => {
      if (openingHours[day][index * 2]
        && openingHours[day][index * 2 + 1]
        && openingHours[day][index * 2] !== openingHours[day][index * 2 + 1]) {
        periods.push(openingHours[day][index * 2] + " - " + openingHours[day][index * 2 + 1]);
      }
    })
    return periods;
  }

  const onAddImage = (image) => {
    setImages([...images, image]);
  };

  const onDelete = (deletedImageId, type) => {
    if (type === 'logo') {
      setLogo([]);
    }
    if (deletedImageId) {
      const result = images.filter(image => image?._id !== deletedImageId);
      return setImages(result);
    }
  };

  const onDeleteImage = (imageId) => {
    const config = {
      headers: {
        "content-type": "application/json",
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };

    const data = {
      partner_id: localStorage.getItem("mooze_admin_user"),
      image_id: imageId
    };

    let url =
      process.env.REACT_APP_HOST +
      '/deleteImage'
    axios
      .post(url, data, config)
      .catch(function (error) {
        message.error("Error while adding item", 5);
      })
      .then(async (res) => {
        getPartnerDetails();
      });

  }

  const onTagSelect = (tagId) => {
    console.log(tagId, 'tagId')
    const foundItem = selectedTags.find(item => item === tagId);
    if (foundItem) {
      const result = selectedTags.filter(item => item !== tagId);
      return setSelectedTags(result);
    }
    return setSelectedTags([...selectedTags, tagId]);
  };

  const uploadLogo = (file) => {
    setLogo([file]);
  };

  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onPrev = async (file) => {
    console.log(file , 'file is here in pre vie')
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };


  const onPrevLogo = (file) =>{
    const image = new Image();
    image.src = file;
    const imgWindow = window.open(file);
    imgWindow.document.write(image.outerHTML);
  }





  const onChangeItem = async (file) => {
    const convertedLogo = await toBase64(file)
    if (file?.status !== "uploading") {
      setLogo(convertedLogo);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };


    const formData = new FormData();
    setLoading('logo')
    formData.append("base64string", convertedLogo);
    formData.append("partner_id", localStorage.getItem("mooze_admin_user"));
    let url =
      process.env.REACT_APP_HOST+'/updatePartnerLogo';

    axios
      .post(url, formData, config)
      .catch(function (error) {
        message.error("Error while adding item", 5);
      })
      .then(async (res) => {
        setLoading('')
        message.success("Logo Added", 5);
        getPartnerDetails();
      });


  };


  const handleRemoveLogo = () => {

    setLogo('')
    const config = {
      headers: {
        "content-type": "application/json",
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };

    let url =
      process.env.REACT_APP_HOST+'/deletePartnerLogo';

    axios
      .post(url, {
        "partner_id" : localStorage.getItem("mooze_admin_user")
      }, config)
      .catch(function (error) {
        message.error("Error while adding item", 5);
      })
      .then(async (res) => {
        message.success("Photo Removed", 5);
      });

  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });


  const onSubmit = async (file) => {
    console.log(file, 'this is file');
    const image = await toBase64(file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };

    const formData = new FormData();
    formData.append("base64string", image);
    formData.append("partner_id", localStorage.getItem("mooze_admin_user"));
    setLoading('pictures');

    let url =
      process.env.REACT_APP_HOST +
      '/addImage'
    axios
      .post(url, formData, config)
      .catch(function (error) {
        message.error("Error while adding item", 5);
      })
      .then(async (res) => {
        getPartnerDetails();
        setLoading('');
      });

  }


  return (
    <div className="partner-account-page">
      {showModal === true ? (
        <PartnerOpeningHours
          showModal={showModal}
          setShowModal={setShowModal}
          openingHours={openingHours}
          rushHours={rushHours}
          setOpeningHours={setOpeningHours}
          setRushHours={setRushHours}
        />
      ) : null}
      <div className="partner-images">
        <div className="add-partner-logo">
          <div style={{position: 'relative' ,height :200 , width :200}} onMouseOver={()=>{

            if(logo && logo !== 'leaved'){
              setShowDelete(logo)
            }

          }} onMouseLeave={()=>{setShowDelete('leaved')}}>
            {
              showDelete === logo &&
              <>

                <Overlay />
                <RemoveIcon onClick={handleRemoveLogo} />
                <PreviewIcon onClick={ () => {
                  onPrevLogo(logo)
                }} />
              </>
            }
            <ImgCrop rotate beforeCrop={beforeUpload}>
              <Upload
                customRequest={dummyRequest}
                listType="picture-card"
                fileList={itemPhoto}
                onPreview={onPrev}
                beforeUpload={async(e)=>{
                  await onChangeItem(e)
                  return true
                }}
              >

                {logo &&
                  <>
                    {loading === 'logo' ? <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                      Uploading...
                    </div> : <div style={{width: '100%', height: '100%'}}>
                      <img src={logo} alt='Image' width={200} height={200} />
                    </div>}

                  </>
                }
                {!logo && "Une photo de votre produit"}

              </Upload>
            </ImgCrop>
          </div>

        </div>
        <div className="add-partner-homepage-image">
          <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            {
              images.map(item => {
                return <ImgCrop rotate key={item?.id}>
                  <div onMouseOver={() => {
                    setShowDelete(item?.id)
                  }} style={{position: 'relative', margin: 10}}>
                    {item && showDelete === item?.id &&
                      <>
                        <Overlay />
                        <RemoveIcon onClick={() => {
                          onDeleteImage(item.id)
                        }} />
                        <PreviewIcon onClick={async () => {
                          await onPrev(item)
                        }} />
                      </>
                    }
                    {item?.url ?
                      <Upload
                        customRequest={dummyRequest}
                        listType="picture-card"
                        fileList={homePagePhoto}
                        onPreview={onPrev}
                      >

                        <div style={{width: '100%', height: '100%'}}>
                          <img src={item?.url} alt='Image' width={200} height={200} />
                        </div>
                      </Upload> : null
                    }
                    {
                      !item ?
                        <>
                          <ImgCrop rotate key={item?.id}>
                            <Upload
                              customRequest={dummyRequest}
                              listType="picture-card"
                              fileList={homePagePhoto}
                              onPreview={onPrev}
                              beforeUpload={(e) => {
                                onSubmit(e);
                                return true
                              }}
                            >
                              {loading === 'pictures' ? 'Uploading...' : 'Une photo de votre produit'}
                            </Upload>
                          </ImgCrop>
                        </> : null
                    }
                  </div>
                </ImgCrop>;
              })
            }
          </div>
        </div>
      </div>
      <Form
        initialValues={partnerDetails}
        name="partner-account-form"
        className="partner-account-form"
        onFinish={updateDetails}
      >
        <div className="partner-form-div">
          <div>
            <Form.Item
              label="Nom"
              labelCol={{span: 24}}
              className="partner-name"
              name="admin_name"
              rules={[{required: true, message: "Ceci est nécessaire"}]}
            >
              <Input bordered={false} />
            </Form.Item>
            <Form.Item
              label="Nom de l'établissement"
              labelCol={{span: 24}}
              className="partner-establishment-name"
              name="establishment_name"
              rules={[{required: true, message: "Ceci est nécessaire"}]}
            >
              <Input bordered={false} />
            </Form.Item>
            <Form.Item
              label="Email admin"
              labelCol={{span: 24}}
              className="partner-email-admin"
              name="email_admin"
              rules={[{required: true, message: "Ceci est nécessaire"}]}
            >
              <Input type="email" bordered={false} />
            </Form.Item>
            <Form.Item
              label="Email pro app"
              labelCol={{span: 24}}
              className="partner-email-pro"
              name="email_pro"
              rules={[{required: true, message: "Ceci est nécessaire"}]}
            >
              <Input type="email" bordered={false} />
            </Form.Item>
            <Form.Item
              label="Nom de la rue"
              labelCol={{span: 24}}
              className="partner-address"
              name="street"
              rules={[{required: true, message: "Ceci est nécessaire"}]}
            >
              <Input bordered={false} />
            </Form.Item>
            <Form.Item
              label="Ville"
              labelCol={{span: 24}}
              className="partner-address"
              name="city"
              rules={[{required: true, message: "Ceci est nécessaire"}]}
            >
              <Input bordered={false} />
            </Form.Item>
            <Form.Item
              label="Code postal"
              labelCol={{span: 24}}
              className="partner-latitude"
              name="zipcode"
              rules={[{required: true, message: "Ceci est nécessaire"}]}
            >
              <Input type="number" bordered={false} />
            </Form.Item>
            <Form.Item
              label="Latitude"
              labelCol={{span: 24}}
              className="partner-latitude"
              name="latitude"
              rules={[{required: true, message: "Ceci est nécessaire"}]}
            >
              <Input type="number" step="0.001" bordered={false} />
            </Form.Item>
            <Form.Item
              label="Longitude"
              labelCol={{span: 24}}
              className="partner-longitude"
              name="longitude"
              rules={[{required: true, message: "Ceci est nécessaire"}]}
            >
              <Input type="number" step="0.001" bordered={false} />
            </Form.Item>
            <Form.Item
              label="Link of Website"
              labelCol={{span: 24}}
              className="partner-address"
              name="website_link"
            >
              <Input bordered={false} />
            </Form.Item>
            <Form.Item
              label="Link of Instagram"
              labelCol={{span: 24}}
              className="partner-address"
              name="instagram_link"
            >
              <Input bordered={false} />
            </Form.Item>
            <Form.Item
              label="Link of Uber Eats"
              labelCol={{span: 24}}
              className="partner-address"
              name="deliveryapp_link"
            >
              <Input bordered={false} />
            </Form.Item>
            <Form.Item
              initialValue={0.0}
              label="Currency"
              labelCol={{span: 24}}
              className="create-restaurant-menu-item-price"
              name="currency"
              rules={[{required: true, message: "Ceci est nécessaire"}]}
            >
              <Dropdown
                title="Choose Currency"
                onSelect={(data) => SetSelectedCurrency(data)}
                options={dropdownOptions}
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="update-partner-account-btn"
            >
              Enregistrer
            </Button>
          </div>
          <div className="partner-opening-hours-div">
            <h2>
              Horaires d'ouvertures
              <Button
                className="modify-hours-btn"
                type="text"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Modifier
              </Button>
            </h2>
            <div className="partner-account-page-opening-hours">
              {Object.keys(openingHours).map((day) => (
                <div
                  key={day}
                  className="partner-account-page-opening-hours-table"
                >
                  <div>{day}:</div>
                  <div>
                    {openingHours[day].length !== 0 ? (
                      getOpenPeriods(openingHours, day).join(', ')
                    ) : (
                      <span>Fermé</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <br></br>
            {localStorage.getItem("mooze_admin_user_type_id") === "3" ? (
              <>
                {/*<div className="partner-butcher-dropdown">
                <h2>
                  Temps de préparation accordé<br></br>
                </h2>
                <Form.Item
                  rules={[{ required: true, message: "Ceci est nécessaire" }]}
                >
                  <Select
                    className="partner-butcher-select"
                    onChange={async (value) => {
                      await setPrepHours(value);
                    }}
                    defaultValue={
                      partnerDetails.prep_hours
                        ? partnerDetails.prep_hours
                        : null
                    }
                  >
                    <Select.Option value={0.5}>30 min</Select.Option>
                    <Select.Option value={1}>1 heure</Select.Option>
                    <Select.Option value={2}>2 heures</Select.Option>
                    <Select.Option value={3}>3 heures</Select.Option>
                    <Select.Option value={4}>4 heures</Select.Option>
                  </Select>
                </Form.Item>
              </div>*/}
                <EstimationTime setEstimationTime={setEstimationTime} estimationTime={estimationTime}
                                format={timeFormat} />
              </>
            ) : null}
            {
              (!partnerDetails.stripe_acc_id) ? (<StripeButton partnerDetails={partnerDetails} />) : (
                <div>Stripe Account Setup Complete</div>)
            }

            {
              tags.map(item => {
                return <Checkbox
                  item={item}
                  checked={!!selectedTags.find(selectedTag => item._id === selectedTag)}
                  onClick={onTagSelect}
                />;
              })
            }
            <div style={{
              marginTop: '10%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
              <h2 style={{alignSelf: 'flex-start'}}>Table Logo:</h2>
              <QRCode value={qrCodeUrl} size={150} logoImage={logoImage} logoHeight={85} logoWidth={85} logoSize={150}
                      logoOpacity={0.99} />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PartnerAccount;
