import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Form,
  // Image,
  Input,
  message,
  Button,
  Upload,
  Select,
  Tooltip,
  Dropdown
} from "antd";
import ImgCrop from "antd-img-crop";
import { Image as AntImg }  from 'antd';
import {
  SaveFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import EstimationTime from "./common/EstimationTime";
import {defaultPreparationTime} from "../constants/options";

const { Option } = Select;

const CreateRestaurantMenuItem = (props) => {
  const timeFormat = "HH:mm";
  const [estimationTime, setEstimationTime] = useState(defaultPreparationTime);
  const [itemPhoto, setItemPhoto] = useState([]);
  const [search, setSearch] = useState("");
  const [sizeIcon, setSizeIcon] = useState("");
  const [sizeIcons, setSizeIcons] = useState([]);
  const [ingredientIcons, setIngredientIcons] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState({});
  const [isSelectedIcon, setIsSelectedIcon] = useState(0);
  const [selectedIngredients, setSelectedIngredients] = useState({});
  const [ingredientCategory, setIngredientCategory] = useState("");

  useEffect(() => {
    console.log(props);
    var err_flag = 0;
    let url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_GET_REFERENCE_DATA;
    let params = { category: "size_icons" };
    axios
      .get(url, { params: params })
      .catch(function (error) {
        message.error("Error while loading size icons", 5);
        err_flag = 1;
      })
      .then(async (res) => {
        if (!err_flag) {
          await setSizeIcons(res.data);
        }
      });

    let params_ingredients = { category: "ingredients" };

    axios
      .get(url, { params: params_ingredients })
      .catch(function (error) {
        message.error("Error while loading ingredients icons", 5);
        err_flag = 1;
      })
      .then(async (res) => {
        if (!err_flag) {
          await setIngredientIcons(res.data);
        }
      });
  }, []);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChangeItem = ({ file }) => {
    console.log('file status: ', file.status)
    if (file.status !== "uploading") {
      if (itemPhoto.length !== 0) {
        setItemPhoto([]);
      } else {
        setItemPhoto([file]);
      }
    }
  };


  const searchBar = (search) => {
    setSearch(search.target.value);
  };

  const addSmall = async () => {
    if (selectedSizes["small"]) return;
    else {
      var newSizes = JSON.parse(JSON.stringify(selectedSizes));
      newSizes["small"] = {
        icon: sizeIcon.image[0],
        name: "Taille Petite",
        price: 0.0,
      };
      await setSelectedSizes(newSizes);
    }
  };

  const addMedium = async () => {
    if (selectedSizes["medium"]) return;
    else {
      var newSizes = JSON.parse(JSON.stringify(selectedSizes));
      newSizes["medium"] = {
        icon: sizeIcon.image[0],
        name: "Taille Moyenne",
        price: 0.0,
      };
      await setSelectedSizes(newSizes);
    }
  };

  const addLarge = async () => {
    if (selectedSizes["large"]) return;
    else {
      var newSizes = JSON.parse(JSON.stringify(selectedSizes));
      newSizes["large"] = {
        icon: sizeIcon.image[0],
        name: "Taille Grande",
        price: 0.0,
      };
      await setSelectedSizes(newSizes);
    }
  };

  const changeSetSizeIcons = async (icon) => {
    var newSizes = JSON.parse(JSON.stringify(selectedSizes));
    if (newSizes["small"]) newSizes["small"]["icon"] = icon.image[0];

    if (newSizes["medium"]) newSizes["medium"]["icon"] = icon.image[1];

    if (newSizes["large"]) newSizes["large"]["icon"] = icon.image[2];
    await setSelectedSizes(newSizes);
  };

  const removeSize = async (size) => {
    var newSizes = JSON.parse(JSON.stringify(selectedSizes));
    delete newSizes[size];
    await setSelectedSizes(newSizes);
  };

  const addIngredient = async (ingredient) => {
    if (selectedIngredients[ingredient._id]) return;
    else {
      var newIngredient = JSON.parse(JSON.stringify(selectedIngredients));
      newIngredient[ingredient._id] = {
        name: ingredient.name,
        icon: ingredient.image,
        price: 0.0,
        custom: false,
      };
      await setSelectedIngredients(newIngredient);
    }
  };

  const removeIngredient = async (ingredient) => {
    var newIngredients = JSON.parse(JSON.stringify(selectedIngredients));
    delete newIngredients[ingredient];
    await setSelectedIngredients(newIngredients);
  };

  const setSizePrice = async (price, size) => {
    var newSizes = JSON.parse(JSON.stringify(selectedSizes));
    newSizes[size]["price"] = price;
    await setSelectedSizes(newSizes);
  };

  const setIngredientPrice = async (price, ingredient) => {
    var newIngredients = JSON.parse(JSON.stringify(selectedIngredients));
    newIngredients[ingredient]["price"] = price;
    await setSelectedIngredients(newIngredients);
  };

  const validateItemData = (price) => {
    console.log("PRIZE: ", price);
    let err_flag = 0;
    let ef = 0;
    if (itemPhoto.length === 0) {
      message.error(
        "Veuillez vous assurer que vous avez ajouté une photo de votre élément de menu",
        3
      );
      return 1;
    // } else if (Object.keys(selectedSizes).length === 0) {
    //   message.error("Vous devez sélectionner au moins une taille", 3);
    //   return 1;
    } else {
      Object.keys(selectedSizes).map((size) => {
        console.log(selectedSizes[size]["price"] === "");

        if (selectedSizes[size]["price"] === "") err_flag = 1;
        return;
      });
      if (err_flag === 1) {
        message.error("Le prix dans la taille ne doit pas être vide", 3);
        return 1;
      }
      err_flag = 0;
      // Object.keys(selectedSizes).map((size) => {
      //   if (parseInt(selectedSizes[size]["price"]) === parseInt(price)) ef++;
      // });
      // if (ef === 0) {
      //   message.error(
      //     "Le prix doit correspondre au prix d'au moins une taille",
      //     3
      //   );
      //   return 1;
      // }
      Object.keys(selectedIngredients).map((ingredient) => {
        if (selectedIngredients[ingredient]["price"] === "") err_flag = 1;
        return;
      });
      if (err_flag === 1) {
        message.error("La prix dans les ingrédients ne doit pas être vide", 3);
        return 1;
      }
      return 0;
    }
  };

  const addItem = (values) => {
    var err_flag = 0;
    console.log(values);

    if (validateItemData(values.price) === 1) return;
    else {
      message.loading("Please wait for item upload!", 3);
      const formData = new FormData();
      formData.append("file", itemPhoto[0].originFileObj);
      formData.append("user_id", localStorage.getItem("mooze_admin_user"));
      formData.append("category_id", props.categoryId);
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("price", values.price);
      formData.append("sizes", JSON.stringify(selectedSizes));
      formData.append("ingredients", JSON.stringify(selectedIngredients));
      formData.append("estimation_time", JSON.stringify(estimationTime));

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
        },
      };
      let url =
        process.env.REACT_APP_HOST +
        process.env.REACT_APP_ADD_RESTAURANT_ITEM_API;
      axios
        .post(url, formData, config)
        .catch(function (error) {
          message.error("Error while adding item", 5);
        })
        .then(async (res) => {
          message.success("Item added!", 5);
          console.log(res);
          await props.setPageView(0);
        });
    }
  };

  if (sizeIcons.length === 0 || ingredientIcons.length === 0) return null;
  return (
    <div className="create-restaurant-menu-item">
      <Row>
        <Col span={12}>
          <h2>Image</h2>
          <div className="create-restaurant-menu-item-image">
            <ImgCrop rotate beforeCrop={beforeUpload}>
              <Upload
                customRequest={dummyRequest}
                listType="picture-card"
                fileList={itemPhoto}
                onChange={onChangeItem}
                onPreview={onPreview}
              >
                {itemPhoto && itemPhoto.length < 1 && "Une photo de votre produit"}
              </Upload>
            </ImgCrop>
          </div>
        </Col>
        <Col span={12}>
          <EstimationTime setEstimationTime={setEstimationTime} estimationTime={estimationTime} format={timeFormat}/>
        </Col>
      </Row>
      <div className="create-restaurant-menu-item-details">
        <div className="create-restaurant-menu-item-form">
          <Form
            name="create-restaurant-menu-item-form"
            className="create-restaurant-menu-item-form"
            onFinish={addItem}
          >
            <Form.Item
              label="Nom:"
              labelCol={{ span: 24 }}
              className="create-restaurant-menu-item-name"
              name="name"
              rules={[{ required: true, message: "Ceci est nécessaire" }]}
            >
              <Input bordered={false} />
            </Form.Item>
            <Form.Item
              label="Description"
              labelCol={{ span: 24 }}
              className="create-restaurant-menu-item-description"
              name="description"
              rules={[{ required: true, message: "Ceci est nécessaire" }]}
            >
              <Input bordered={false} />
            </Form.Item>
            <Form.Item
              initialValue={0.0}
              label="Prix"
              labelCol={{ span: 24 }}
              className="create-restaurant-menu-item-price"
              name="price"
              rules={[{ required: true, message: "Ceci est nécessaire" }]}
            >
              <Input type="number" step="0.1" bordered={false} />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="create-restaurant-menu-item-add-btn"
            >
              <SaveFilled /> Enregistrer
            </Button>
            <br></br>
            <br></br>
            <Button
              type="primary"
              className="create-restaurant-menu-item-cancel-btn"
              onClick={() => {
                props.setPageView(0);
              }}
            >
              Annuler
            </Button>
          </Form>
        </div>
        <div className="create-restaurant-menu-item-options">
          <h3>
            Vous avez la possibilité d'ajouter différentes tailles pour le même
            menu&nbsp;&nbsp;
            <Tooltip title="Vous pouvez sélectionner les tailles après avoir sélectionné un jeu d'icônes pour les tailles">
              <ExclamationCircleFilled className="exlamation-icon" />
            </Tooltip>
          </h3>
          <div className="create-restaurant-menu-item-options-row">
            <div className="create-restaurant-menu-item-options-row-element">
              <div className="create-restaurant-menu-item-sizes">
                <Button
                  disabled={sizeIcon === "" ? true : false}
                  className="create-restaurant-menu-size-button-unselected"
                  onClick={async () => {
                    await addSmall();
                  }}
                >
                  Petit
                </Button>
                <Button
                  disabled={sizeIcon === "" ? true : false}
                  onClick={async () => {
                    await addMedium();
                  }}
                  className="create-restaurant-menu-size-button-unselected"
                >
                  Moyenne
                </Button>
                <Button
                  disabled={sizeIcon === "" ? true : false}
                  onClick={async () => {
                    await addLarge();
                  }}
                  className="create-restaurant-menu-size-button-unselected"
                >
                  Grande
                </Button>
              </div>
              <div className="create-restaurant-menu-size-icons">
                <div className="menu-size-icons-group">
                  {sizeIcons.map((icon) => (
                    <div
                      className={
                        isSelectedIcon === icon._id
                          ? "size-icon-div-selected"
                          : "size-icon-div"
                      }
                      key={icon._id}
                    >
                      <AntImg
                        src={icon.image[0]}
                        width={50}
                        preview={false}
                        onClick={(e) => {
                          setSizeIcon(icon);
                          changeSetSizeIcons(icon);
                          setIsSelectedIcon(icon._id);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="create-restaurant-menu-item-options-row-element-small">
              <div className="selected-sizes-scroll-div">
                Mes Tailles
                {Object.keys(selectedSizes).map((size) => (
                  <div key={size} className="selected-size-row">
                    <div className="selected-size-row-img">
                      <AntImg
                        src={selectedSizes[size]["icon"]}
                        preview={false}
                        className="create-restaurant-menu-item-selected-size"
                      />
                    </div>
                    <div className="selected-size-row-size">
                      {selectedSizes[size]["name"]}
                    </div>
                    <div className="selected-size-row-price">
                      <Input
                        bordered={false}
                        defaultValue={selectedSizes[size]["price"]}
                        type="number"
                        onChange={(e) => setSizePrice(e.target.value, size)}
                      />
                    </div>
                    <div className="selected-size-row-delete">
                      <Button
                        className="delete-size"
                        icon={<CloseCircleFilled />}
                        onClick={() => removeSize(size)}
                      ></Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <h3>
            Permettez à vos clients d'ajouter ou de supprimer des ingrédients du
            menu
          </h3>
          <div className="create-restaurant-menu-item-options-row create-restaurant-menu-item-options-row-bottom">
            <div className="create-restaurant-menu-item-options-row-element">
              <Input
                className="create-restaurant-menu-ingredient-search"
                placeholder="Rechercher"
                onChange={searchBar}
                bordered={false}
              ></Input>
              <div className="create-restaurant-menu-ingredient-categories">
                <Select
                  placeholder="Type d'ingrédient"
                  onChange={(value) => setIngredientCategory(value)}
                >
                  <Option value="">Toute</Option>
                  <Option value="meat">Viandes</Option>
                  <Option value="fish">Poisson fruits de mer</Option>
                  <Option value="vegetables">Légumes</Option>
                  <Option value="fruits">Fruits</Option>
                  <Option value="spices">Epices</Option>
                  <Option value="other">Autres ingrédients</Option>
                </Select>
              </div>
              <div className="create-restaurant-menu-ingredient-icons">
                <div className="menu-ingredient-icons-group">
                  {ingredientIcons.map((ingredient_icon) => (
                    <div key={ingredient_icon._id}>
                      {ingredient_icon.name
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) >= 0 &&
                      ingredient_icon.subcategory
                        .toLowerCase()
                        .indexOf(ingredientCategory.toLowerCase()) >= 0 ? (
                        <AntImg
                          src={ingredient_icon.image}
                          width={50}
                          preview={false}
                          onClick={() => {
                            addIngredient(ingredient_icon);
                          }}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="create-restaurant-menu-item-options-row-element-small">
              <div className="selected-ingredients-scroll-div">
                Mes ingrédients
                {Object.keys(selectedIngredients).map((ingredient) => (
                  <div key={ingredient} className="selected-ingredient-row">
                    <div className="selected-ingredient-row-img">
                      <AntImg
                        src={selectedIngredients[ingredient]["icon"]}
                        className="create-restaurant-menu-item-selected-ingredient"
                        preview={false}
                      />
                    </div>
                    <div className="selected-ingredient-row-size">
                      {selectedIngredients[ingredient]["name"]}
                    </div>
                    <div className="selected-ingredient-row-price">
                      <Input
                        bordered={false}
                        defaultValue={selectedIngredients[ingredient]["price"]}
                        onChange={(e) =>
                          setIngredientPrice(e.target.value, ingredient)
                        }
                        type="number"
                      />
                    </div>
                    <div className="selected-ingredient-row-delete">
                      <Button
                        className="delete-ingredient"
                        icon={<CloseCircleFilled />}
                        onClick={() => removeIngredient(ingredient)}
                      ></Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRestaurantMenuItem;
