import React from "react";
import { Component } from "react";
import { Form, Input, Button, Checkbox, Image } from 'antd';
import axios from 'axios';
import sentLogo from '../assets/sent.png'
import { Anchor } from 'antd';

const { Link } = Anchor;

class OnboardingForm extends Component {


    constructor(props) {
        super(props)

    }

    onFinish = (values) => {
        let url = process.env.REACT_APP_HOST + process.env.REACT_APP_ONBOARDING_API
        console.log('Received values of form: ', values, url);
        axios.post(url, values).catch(function (error) {
            console.log(error);
        }).then( (res) => {
            console.log('submitted form!', res);
            this.props.onboardingRequestSuccessful();
        })
    }

    render() {
        return (
            <div className="login__onboardingForm">
                <h1 align="center">
                    <p className="onboarding_form_title">Rejoignez la Révolution</p>
                </h1>
                <h2 align="center">
                    <p className="onboarding_form_subtitle">Devenez partenaire en quelques cliques</p>
                </h2>
                <Form
                    name="partner_onboarding"
                    className="partner_onboardingForm"
                    onFinish={this.onFinish.bind(this)}
                >
                    <Form.Item name="establishment_name" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                        <Input placeholder="Nom de votre établissement" />
                    </Form.Item>
                    <Form.Item name="address" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                        <Input placeholder="Adresse complète" />
                    </Form.Item>
                    <Form.Item name="phone_number" rules={[{ required: true, message: "Ceci est nécessaire", len: 10 }]}>
                        <Input placeholder="numéro de téléphone" type="number" />
                    </Form.Item>
                    <Form.Item name="email" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                        <Input placeholder="Adresse email" type="email" />
                    </Form.Item>
                    <Form.Item name="contact_person_name" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                        <Input placeholder="Votre nom complet" />
                    </Form.Item>
                    <Form.Item >
                        <Checkbox required={true}>
                            J'accepte le présente condition générales d'utilisation
                        </Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="partner_onboardingForm_button">
                            S'INSCRIRE
                        </Button>
                    </Form.Item>
                </Form>
                {
                    this.props.onboardingFormSubmitted ?
                    <div className="login__onboardingForm_image">
                        <Image
                        preview={false}
                        width={100}
                        src={sentLogo}
                    />
                    </div>
                    : null    
                }
            </div>
        )
    }
}

export default OnboardingForm