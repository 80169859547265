import { useEffect, useState } from 'react';
import axios from "axios";
import { message } from "antd";


export default function useGetTags() {
  const [tags, setTags] = useState([]);
  useEffect(() => {
    var error_flag = 0;
    let url =
      process.env.REACT_APP_HOST + '/getTags'
    let axiosData = {
      params: {
        partner_id: localStorage.getItem("mooze_admin_user"),
      },
      headers: {
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };
    axios
      .get(url, axiosData)
      .catch(function (e) {
        message.error("Erreur lors de l'obtention des détails du compte", 5);
        error_flag = 1;
      })
      .then(async (res) => {
        setTags(res?.data?.tags);
      });
  }, []);

  return [tags];
}
