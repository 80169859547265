import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Form,
  //  Image,
  Input, Select, message, Button, Upload
} from "antd";
import ImgCrop from "antd-img-crop";
import { SaveFilled, CloseCircleFilled } from "@ant-design/icons";
import { Image as AntImg } from 'antd';
import EstimationTime from "./common/EstimationTime";
import {defaultPreparationTime} from "../constants/options";
import Dropdown from "../components/Dropdown/Dropdown";
const { Option } = Select;

const CreateRestaurantPlatItem = (props) => {
  const timeFormat = "HH:mm";
  const [estimationTime, setEstimationTime] = useState(defaultPreparationTime);
  const [itemPhoto, setItemPhoto] = useState([]);
  const [ingredientIcons, setIngredientIcons] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedIngredients, setSelectedIngredients] = useState({});
  const [ingredientCategory, setIngredientCategory] = useState("");


  useEffect(() => {
    let url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_GET_REFERENCE_DATA;
    var err_flag = 0;
    let params_ingredients = { category: "ingredients" };

    axios
      .get(url, { params: params_ingredients })
      .catch(function (error) {
        message.error("Error while loading ingredients icons", 5);
        err_flag = 1;
      })
      .then(async (res) => {
        if (!err_flag) {
          await setIngredientIcons(res.data);
        }
      });
  }, []);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChangeItem = ({ file }) => {
    if (file.status !== "uploading") {
      if (itemPhoto.length !== 0) {
        setItemPhoto([]);
      } else {
        setItemPhoto([file]);
      }
    }
  };

  const searchBar = (search) => {
    setSearch(search.target.value);
  };

  const addIngredient = async (ingredient) => {
    if (selectedIngredients[ingredient._id]) return;
    else {
      var newIngredient = JSON.parse(JSON.stringify(selectedIngredients));
      newIngredient[ingredient._id] = {
        name: ingredient.name,
        icon: ingredient.image,
        price: 0.0,
        custom: false,
      };
      await setSelectedIngredients(newIngredient);
    }
  };

  const removeIngredient = async (ingredient) => {
    var newIngredients = JSON.parse(JSON.stringify(selectedIngredients));
    delete newIngredients[ingredient];
    await setSelectedIngredients(newIngredients);
  };

  const setIngredientPrice = async (price, ingredient) => {
    var newIngredients = JSON.parse(JSON.stringify(selectedIngredients));
    newIngredients[ingredient]["price"] = price;
    await setSelectedIngredients(newIngredients);
  };

  const validateItemData = () => {
    var err_flag = 0;
    if (itemPhoto.length === 0) {
      message.error(
        "Veuillez vous assurer que vous avez ajouté une photo de votre élément de menu",
        3
      );
      return 1;
    } else {
      Object.keys(selectedIngredients).map((ingredient) => {
        if (selectedIngredients[ingredient]["price"] === "") err_flag = 1;
        return;
      });
      if (err_flag === 1) {
        message.error("La prix dans les ingrédients ne doit pas être vide", 3);
        return 1;
      }
      return 0;
    }
  };

  const addItem = (values) => {
    console.log(values);
    if (validateItemData() === 1) return;
    else {
      message.loading("Please wait for item upload!", 3);
      const formData = new FormData();
      formData.append("file", itemPhoto[0].originFileObj);
      formData.append("user_id", localStorage.getItem("mooze_admin_user"));
      formData.append("category_id", props.categoryId);
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("price", values.price);
      formData.append("ingredients", JSON.stringify(selectedIngredients));
      formData.append("estimation_time", JSON.stringify(estimationTime));
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
        },
      };
      let url =
        process.env.REACT_APP_HOST + process.env.REACT_APP_ADD_RESTAURANT_ITEM_API;
      axios
        .post(url, formData, config)
        .catch(function (error) {
          message.error("Error while adding item", 5);
        })
        .then(async (res) => {
          message.success("Item added!", 5);
          console.log(res);
          await props.setPageView(0);
        });
    }
  };

  return (
    <div className="create-restaurant-plat-item">
      <Row>
        <Col span={12}>
          <h2>Image</h2>
          <div className="create-restaurant-plat-item-image">
            <ImgCrop rotate beforeCrop={beforeUpload}>
              <Upload
                customRequest={dummyRequest}
                listType="picture-card"
                fileList={itemPhoto}
                onChange={onChangeItem}
                onPreview={onPreview}
              >
                {itemPhoto && itemPhoto.length < 1 && "Une photo de votre produit"}
              </Upload>
            </ImgCrop>
          </div>
        </Col>
        <Col span={12}>
          <EstimationTime setEstimationTime={setEstimationTime} estimationTime={estimationTime} format={timeFormat}/>
        </Col>
      </Row>
      <div className="create-restaurant-plat-item-details">
        <div className="create-restaurant-plat-item-form">
          <Form
            name="create-restaurant-plat-item-form"
            className="create-restaurant-plat-item-form"
            onFinish={addItem}
          >
            <Form.Item
              label="Nom:"
              labelCol={{ span: 24 }}
              className="create-restaurant-plat-item-name"
              name="name"
              rules={[{ required: true, message: "Ceci est nécessaire" }]}
            >
              <Input bordered={false} />
            </Form.Item>
            <Form.Item
              label="Description"
              labelCol={{ span: 24 }}
              className="create-restaurant-plat-item-description"
              name="description"
              rules={[{ required: true, message: "Ceci est nécessaire" }]}
            >
              <Input bordered={false} />
            </Form.Item>
            <Form.Item
              initialValue={0.0}
              label="Prix"
              labelCol={{ span: 24 }}
              className="create-restaurant-plat-item-price"
              name="price"
              rules={[{ required: true, message: "Ceci est nécessaire" }]}
            >
              <Input type="number" step="0.1" bordered={false} />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="create-restaurant-plat-item-add-btn"
            >
              <SaveFilled /> Enregistrer
            </Button>
            <br></br>
            <br></br>
            <Button
              type="primary"
              className="create-restaurant-plat-item-cancel-btn"
              onClick={() => {
                props.setPageView(0);
              }}
            >
              Annuler
            </Button>
          </Form>
        </div>
        <div className="create-restaurant-plat-item-options">
          <h3>
            Sélectionnez les ingrédients que vos clients pourraient ajouter ou
            enlever pour ce plat
          </h3>
          <div className="create-restaurant-plat-item-options-row">
            <div className="create-restaurant-plat-item-options-row-element">
              <Input
                className="create-restaurant-menu-ingredient-search"
                placeholder="Rechercher"
                onChange={searchBar}
              ></Input>
              <div className="create-restaurant-menu-ingredient-categories">
                <Select
                  placeholder="Type d'ingrédient"
                  onChange={(value) => setIngredientCategory(value)}
                >
                  <Option value="">Toute</Option>
                  <Option value="meat">Viandes</Option>
                  <Option value="fish">Poisson fruits de mer</Option>
                  <Option value="vegetables">Légumes</Option>
                  <Option value="fruits">Fruits</Option>
                  <Option value="spices">Epices</Option>
                  <Option value="other">Autres ingrédients</Option>
                </Select>
              </div>
              <div className="create-restaurant-menu-ingredient-icons">
                <div className="menu-ingredient-icons-group">
                  {ingredientIcons.map((ingredient_icon) => (
                    <div key={ingredient_icon._id}>
                      {ingredient_icon.name
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) >= 0 &&
                        ingredient_icon.subcategory
                          .toLowerCase()
                          .indexOf(ingredientCategory.toLowerCase()) >= 0 ? (
                          <AntImg
                            src={ingredient_icon.image}
                            width={50}
                            preview={false}
                            onClick={() => {
                              addIngredient(ingredient_icon);
                            }}
                          />
                        ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="create-restaurant-plat-item-options-row-element-small">
              <div className="selected-ingredients-scroll-div">
                Mes ingrédients
                {Object.keys(selectedIngredients).map((ingredient) => (
                <div key={ingredient} className="selected-ingredient-row">
                  <div className="selected-ingredient-row-img">
                    <AntImg
                      src={selectedIngredients[ingredient]["icon"]}
                      className="create-restaurant-menu-item-selected-ingredient"
                      preview={false}
                    />
                  </div>
                  <div className="selected-ingredient-row-size">
                    {selectedIngredients[ingredient]["name"]}
                  </div>
                  <div className="selected-ingredient-row-price">
                    <Input
                      bordered={false}
                      defaultValue={selectedIngredients[ingredient]["price"]}
                      onChange={(e) =>
                        setIngredientPrice(e.target.value, ingredient)
                      }
                      type="number"
                    />
                  </div>
                  <div className="selected-ingredient-row-delete">
                    <Button
                      className="delete-ingredient"
                      icon={<CloseCircleFilled />}
                      onClick={() => removeIngredient(ingredient)}
                    ></Button>
                  </div>
                </div>
              ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRestaurantPlatItem;
