import React from "react";
import { Component } from "react";
import { Form, Input, Button, Modal, message, Popconfirm } from 'antd';
import axios from "axios";
import './handleAdmin.css';

class HandleAdmins extends Component {
  state = {
    showModal: false,
    admins: [],
    activeTab: 'daily',
    response: {},
    usersActiveTab: 'daily',
    searchActiveTab: 'daily'
  }

  constructor(props) {
    super(props);

    let url = process.env.REACT_APP_HOST + process.env.REACT_APP_GETADMIN_API;
    console.log(url);
    axios
      .get(url).catch(function (error) {
      console.log(error);
    })
      .then(async (res) => {
        console.log(res, 'this is res');
        this.setState({response: res.data});
        this.setState({admins: res.data.admins})
      })
  }

  setActiveTab = (type) => {
    this.setState({
      activeTab: type
    });
  };

  setUserActiveTab = (type) => {
    this.setState({
      usersActiveTab: type
    });
  };

  setSearchActiveTab = (type) => {
    this.setState({
      searchActiveTab: type
    });
  };

  openModal = () => {
    this.setState({
      showModal: true
    })
  }

  createAdmin = (values) => {
    this.setState({
      showModal: false
    })
    let url = process.env.REACT_APP_HOST + process.env.REACT_APP_CREATE_ADMIN_API
    var params = {
      name: values.name,
      email: values.email,
      password: values.password
    }
    axios
      .post(url, params)
      .catch(function (err) {
        console.log(err)
      })
      .then(async (res) => {
        console.log(res);
        if (res.data.message === "Email already exists") {
          message.error(res.data.message, 5)
        } else {
          await this.setState({
            admins: [...this.state.admins, {
              name: values.name,
              email: values.email,
            }]
          })
          message.success("New admin added", 5)
        }
      })
  }

  deleteAdmin(admin) {
    if (this.state.admins.length === 1) {
      message.error("Need to have atleast one admin!", 5)
      return;
    } else {
      var values = {
        email: admin.email
      }
      let url = process.env.REACT_APP_HOST + process.env.REACT_APP_DELETE_ADMIN_API
      axios
        .post(url, values)
        .catch(function (err) {
          console.log(err)
        })
        .then((res) => {
          var newAdmins = this.state.admins.filter(admins => admins.email != admin.email)
          this.setState({
            admins: newAdmins
          })
          message.success("Admin deleted", 5)

        })
    }
  }

  render() {
    console.log(this.state.admins, 'these are admins ');
    return (
      <div className="handle-admins">
        <Modal
          className="add-admin-modal"
          footer={null}
          centered
          width={800}
          visible={this.state.showModal}
          onCancel={() => this.setState({showModal: false})}
        >
          <Form
            name="create-admin-form"
            className="create-admin-form"
            onFinish={this.createAdmin}>
            <div className="admin-form-modal">
              <div>
                <Form.Item
                  name="name" rules={[{required: true, message: "Ceci est nécessaire"}]}>
                  <Input placeholder="Nom complet" />
                </Form.Item>
                <Form.Item
                  name="email" rules={[{required: true, message: "Ceci est nécessaire"}]}>
                  <Input placeholder="Adresse email" type="email" />
                </Form.Item>
                <Form.Item
                  name="password" rules={[{required: true, message: "Ceci est nécessaire"}]}>
                  <Input placeholder="Enter le mot de passe" type="password" />
                </Form.Item>
                <Form.Item
                  name="password_repeat" dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({getFieldValue}) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject("Passwords do not match!");
                      },
                    }),
                  ]}>
                  <Input placeholder="Répéter le mot de passe" type="password" />
                </Form.Item>
              </div>
              <div>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="create-admin-button">
                    Ajouter un Admin
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
        <h1 className="handle-admin-title">
          Les Admin &nbsp;&nbsp;
          <Button type="primary"
                  className="add-admin"
                  onClick={this.openModal}>
            Ajouter
          </Button>
        </h1>
        <h2 className="handle-admin-subtitle">
          Supprimer ou modifier les informations de nos partenaires
        </h2>
        <br></br>
        {this.state.admins?.length > 0 && this.state?.admins?.map(admin => (
          <div className="admin-list" key={admin.email}>
            <div className="admin-list-data">
              <div className="admin-list-subdata"><h2>{admin.name}</h2></div>
              <div className="admin-list-subdata"><h2>{admin.email}</h2></div>
            </div>
            <div className="admin-list-data">
              <Popconfirm placement="right" onConfirm={() => {
                this.deleteAdmin(admin)
              }} title="Delete this admin?">
                <Button type="link" className="delete-admin-button">
                  Supprimer
                </Button>
              </Popconfirm>
            </div>
          </div>
        ))}

        <div className="custom-filter-wrapper">
          <div
            className={`custom-filter custom-daily ${this.state.usersActiveTab === 'daily' ? 'custom-active' : ''}`}
            onClick={() => this.setUserActiveTab('daily')}
          >
            Daily Users
          </div>
          <div
            className={`custom-filter custom-weekly ${this.state.usersActiveTab === 'weekly' ? 'custom-active' : ''}`}
            onClick={() => this.setUserActiveTab('weekly')}
          >
            Weekly Users
          </div>

        </div>
        <div className="custom-filter">
          {this.state.usersActiveTab === 'daily' ?this.state?.response?.daily_user_statistics : this.state?.response?.weeklyUserStatistics}
        </div>



        <div className="custom-filter-wrapper">
          <div
            className={`custom-filter custom-daily ${this.state.searchActiveTab === 'daily' ? 'custom-active' : ''}`}
            onClick={() => this.setSearchActiveTab('daily')}
          >
            Daily Researches
          </div>
          <div
            className={`custom-filter custom-weekly ${this.state.searchActiveTab === 'weekly' ? 'custom-active' : ''}`}
            onClick={() => this.setSearchActiveTab('weekly')}
          >
            Weekly Researches
          </div>

        </div>
        <div className="custom-filter">
          {this.state.searchActiveTab === 'daily' ?this.state?.response?.daily_research_total : this.state?.response?.weekly_research_total}
        </div>



        <div className="custom-filter-wrapper">
          <div
            className={`custom-filter custom-daily ${this.state.activeTab === 'daily' ? 'custom-active' : ''}`}
            onClick={() => this.setActiveTab('daily')}
          >
            Daily Filters Research
          </div>
          <div
            className={`custom-filter custom-weekly ${this.state.activeTab === 'weekly' ? 'custom-active' : ''}`}
            onClick={() => this.setActiveTab('weekly')}
          >
            Weekly Filters Research
          </div>

        </div>
        <span className="custom-title-filter">Filters: </span>
        <div className="custom-row-item-wrapper">
          {
            this.state.response?.[this.state.activeTab === 'daily' ? 'daily_tag_statistics': 'weekly_tag_statistics']?.map(item => {
              return <div className="custom-row-item">
                <span>{item.name}</span>
                <span>{item.avg}</span>
              </div>
            })
          }
        </div>
      </div>
    )
  }
}

export default HandleAdmins;
