import React from "react";
import { Component } from "react";
import { Menu, Image, message, Layout } from "antd";
import axios from "axios";
import Img from "../assets/moozeforbusiness.png";
import Categories from "./Categories";
import Items from "./Items";
import PartnerAccount from "./PartnerAccount";
import Sauces from "./Sauces";
import Boissons from "./Boissons";
import Orders from "./Orders";

const {Content, Sider} = Layout;

class PartnerDashboard extends Component {
  state = {
    key: "orders",
    categories: [],
    isSet: false
  };

  constructor() {
    super();
    axios.defaults.headers.common['Authorization'] = 'BEARER ' + localStorage.getItem("mooze_admin_jwt");
    let url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_GET_CATEGORIES_API;
    let axiosData = {
      params: {id: localStorage.getItem("mooze_admin_user")},
      headers: {
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };
    axios
      .get(url, axiosData)
      .catch(function (err) {
        message.error("Problème d'obtention des catégories", 5);
      })
      .then(async (res) => {
        console.log('categories fetched', res);
        if (res?.data?.categories) {
          await this.setState({
            categories: res.data.categories,
            isSet: true
          });
        } else {
          await this.setState({
            isSet: true
          })
        }
        console.log(this.state.categories);
      });
    this.changeCategories = this.changeCategories.bind(this)
  }

  changeKey(key) {
    this.setState({
      key: key,
    });
  }

  changeCategories(categories) {
    this.setState({
      categories: categories
    })
  }

  setSwitch(key) {
    let _key = key
    console.log("--->", this.state.categories, key);
    if (this.state.categories[key] && this.state.categories[key].type === 'sauces')
      key = 'sauces'
    else if (this.state.categories[key] && this.state.categories[key].type === 'boissons')
      key = 'boissons'
    console.log("KEY is", key);
    switch (key) {
      case "orders":
        return <Orders />
      case "account":
        return <PartnerAccount />;
      case "create":
        return <Categories changeCategories={this.changeCategories} categories={this.state.categories} />;
      case "deconnexion":
        this.logout();
      case "sauces":
        return <Sauces sauces={this.state.categories[_key]} />
      case "boissons":
        return <Boissons />
      default:
        return <Items category={this.state.categories[key]} categoryId={key} pageView={0} />;
    }
  }

  logout() {
    console.log("Logout called");
    message.loading("Please wait...", 2);
    localStorage.removeItem("mooze_admin_jwt");
    localStorage.removeItem("mooze_admin_user");
    localStorage.removeItem("mooze_admin_user_type_id");
    message.success("Logged out!", 3);
    window.location.reload();
  }

  render() {
    if (!this.state.isSet) return null;
    return (
      <Layout className="partner-dashboard">
        <Sider width={200}>
          <div className="mooze-business-logo">
            <Image
              className="mooze-logo-image"
              src={Img}
              alt="mooze for business"
              preview={false}
            ></Image>
          </div>
          <Menu
            selectedKeys={this.state.key}
            onClick={(value) => {
              this.setState({key: value.key});
            }}
          >
            <Menu.Item key="orders">Commandes</Menu.Item>
            <Menu.Item key="account">Mon compte</Menu.Item>

            {Object.keys(this.state.categories).map((category) => (
              <Menu.Item key={category}>
                <Image
                  preview={false}
                  width={35}
                  src={this.state.categories[category].icon}></Image>
                &nbsp;
                {this.state.categories[category].name}
              </Menu.Item>
            ))}

            <Menu.Item key="create">Creer une categorie</Menu.Item>
            <Menu.Item key="deconnexion">Deconnexion</Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Content
            className={
              this.state.key === "creer"
                ? "admin-area-content-creer"
                : "admin-area-content"
            }
          >
            {this.setSwitch(this.state.key)}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default PartnerDashboard;
