import React, { useState } from "react";
import { Row, Col, Form, Checkbox, Input, message, Button, Upload, Radio } from "antd";
import axios from "axios";
import ImgCrop from "antd-img-crop";
import { SaveFilled } from "@ant-design/icons";
import EstimationTime from "./common/EstimationTime";
import {defaultPreparationTime} from "../constants/options";

const CheckboxGroup = Checkbox.Group;


const CreateButcherItem = (props) => {
  const timeFormat = "HH:mm";
  const [estimationTime, setEstimationTime] = useState(defaultPreparationTime);
  const [itemPhoto, setItemPhoto] = useState([]);
  const [checked, setChecked] = useState([]);
  const [cutType, setCutType] = useState("Entier");
  const cutOptions = ['Tranche', 'Cube', 'Entier'];
  const [checkedList, setCheckedList] = useState(0);

  const onChange = (values) => {
    setCutType(values.target.value);
  }

  const onCheckChange = (list) => {
    setCheckedList(list);
  }

  const addItem = (values) => {
    if (itemPhoto.length === 0 || (cutType === "Cut" && !checkedList)) {
      message.error(
        "Veuillez vous assurer d'avoir ajouté une photo et vérifié le type de choix de produits",
        3
      );
      return;
    } else {
      message.loading("Please wait for item upload!", 3);
      const formData = new FormData();
      formData.append("file", itemPhoto[0].originFileObj);
      formData.append("user_id", localStorage.getItem("mooze_admin_user"));
      formData.append("category_id", props.categoryId);
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("price_kg", values.price_kg);
      formData.append("price_piece", values.price_piece);
      formData.append("cut_type", cutType);
      formData.append("estimation_time", JSON.stringify(estimationTime));
      if (cutType === 'Cut')
        formData.append("cut_shape", JSON.stringify(checkedList));
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
        },
      };
      let url =
        process.env.REACT_APP_HOST + process.env.REACT_APP_ADD_BUTCHER_ITEM_API;
      axios
        .post(url, formData, config)
        .catch(function (error) {
          message.error("Error while adding item", 5);
        })
        .then(async (res) => {
          message.success("Item added!", 5);
          console.log(res);
          await props.setPageView(0);
        });
    }
  };

  const onCheck = (list) => {
    setChecked(list);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChangeItem = ({ file }) => {
    if (file.status !== "uploading") {
      if (itemPhoto.length !== 0) {
        setItemPhoto([]);
      } else {
        setItemPhoto([file]);
      }
    }
  };

  return (
    <div className="create-butcher-item">
      <Row>
        <Col span={12}>
          <div className="add-butcher-item-image">
            <ImgCrop rotate beforeCrop={beforeUpload}>
              <Upload
                customRequest={dummyRequest}
                listType="picture-card"
                fileList={itemPhoto}
                onChange={onChangeItem}
                onPreview={onPreview}
              >
                {itemPhoto && itemPhoto.length < 1 && "Une photo de votre produit"}
              </Upload>
            </ImgCrop>
          </div>
        </Col>
        <Col span={12}>
          <EstimationTime setEstimationTime={setEstimationTime} estimationTime={estimationTime} format={timeFormat}/>
        </Col>
      </Row>
      <Form
        name="add-butcher-item-form"
        className="add-butcher-item-form"
        onFinish={addItem}
      >
        <div className="add-butcher-item-form-div">
          <div>
            <Form.Item
              label="Nom:"
              labelCol={{ span: 24 }}
              className="add-butcher-item-name"
              name="name"
              rules={[{ required: true, message: "Ceci est nécessaire" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              labelCol={{ span: 24 }}
              className="add-butcher-item-description"
              name="description"
              rules={[{ required: true, message: "Ceci est nécessaire" }]}
            >
              <Input />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="add-butcher-item-add-btn"
            >
              <SaveFilled /> Enregistrer
            </Button>
            &nbsp; &nbsp;
            <Button
              type="primary"
              className="add-butcher-item-cancel-btn"
              onClick={() => {
                props.setPageView(0);
              }}
            >
              Annuler
            </Button>
          </div>
          <div className="add-butcher-item-choose-cut">
            <Form.Item
              label="Choisissez comment vendre cet article"
              labelCol={{ span: 24 }}
            >
              <Radio.Group onChange={onChange} defaultValue="Entier" >
                <Radio value={"Entier"}>Entier</Radio>
                <Radio value={"Cut"}>Morceau coupé</Radio>
              </Radio.Group>
            </Form.Item>
            {
              cutType === "Entier" ?
                <div className="add-butcher-item-price-piece">
                  <Form.Item
                    label="Prix au entier piece:"
                    rules={[{ required: true, message: "Ceci est nécessaire", min: 0.01 }]}
                    name="price_piece"
                    labelCol={{ span: 24 }}
                  >
                <Input type="number" step="0.01" />
                  </Form.Item>
                </div>
                : <div className="add-butcher-item-price-kg">
                  <Form.Item
                    label="Prix au kg:"
                    name="price_kg"
                    rules={[{ required: true, message: "Ceci est nécessaire", min: 0.01 }]}
                    labelCol={{ span: 24 }}
                  >
                <Input type="number" step="0.01" />
                  </Form.Item>
                  <Form.Item
                    label="Vous vendez les pièces coupées comme:"
                    labelCol={{ span: 24 }}
                  >
                    <CheckboxGroup options={cutOptions} onChange={onCheckChange} >
                    <Checkbox value="Tranche">En tranche</Checkbox>
                    <Checkbox value="Cube">En cube</Checkbox>
                    <Checkbox value="Entier">Entier</Checkbox>
                    </CheckboxGroup>
                  </Form.Item>
                </div>
            }
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CreateButcherItem;
