import React from "react";
import { Component } from "react";
import { Form, Input, Button, message } from 'antd';
import axios from "axios";

class ForgotPasswordForm extends Component {

    constructor(props)
    {
        super(props)
        message.config({
            maxCount: 1
        });
    }

    onFinish(values) {
        message.loading('Please wait...', 2);
        message.info('Si l\'identifiant de messagerie entré existe, vous recevrez des instructions de réinitialisation du mot de passe dessus', 3);
         let url = process.env.REACT_APP_HOST + process.env.REACT_APP_FORGOT_PASSWORD;
         let axiosHeaders = {
             headers: {
                 'user_type_id': this.props.userTypeId,
             }
         }
         axios.post(url,values, axiosHeaders).then((res)=> {
            //  loadLocalStorage(res.data);
 
          }).catch(function (error) {
             // if (JSON.stringify(error).indexOf("401") >= 0);
                //  message.error('Invalid Credentials!', 3)
         })
        //  window.location.reload();
    }

    render() {
        return (
            <div className="login__forgotPasswordForm">
                <div className="forgotPasswordForm_outer">
                <h2>
                    <p className="forgotPassword_title">
                        Réinitialisez votre mot de pass en
                        entrant votre Email.
                    </p>
                </h2>
                <Form
                    name="partner_forgotPassword"
                    className="partner_forgotPasswordForm"
                    onFinish={this.onFinish.bind(this)}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Ceci est nécessaire",
                            }
                        ]}
                    >
                        <Input
                            type="email"
                            placeholder="Email"
                        />
                    </Form.Item>
                    <Form.Item>
                            <Button type="primary" htmlType="submit" className="partner_loginForm_button">
                                Envoyer
                        </Button>
                        </Form.Item>
                </Form>
                </div>
            </div>
        );
    }

}

export default ForgotPasswordForm;
