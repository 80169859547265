import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, message, Modal, Switch, TimePicker } from "antd";
import moment from "moment";

const PartnerOpeningHours = (props) => {
  const format = "HH:mm";
  const [openingHoursModal, setOpeningHoursModal] = useState(
    props.openingHours
  );
  const [rushHoursModal, setRushHoursModal] = useState(
    props.rushHours
  );
  useEffect(() => {
  }, [props.showModal, props.openingHours, props.rushHours]);

  const setTime = async (day, timeString, index) => {
    const newOpeningHours = JSON.parse(JSON.stringify(openingHoursModal));
    newOpeningHours[day][index] = timeString;
    await setOpeningHoursModal(newOpeningHours);
  };

  const dayToggle = async (day, checked) => {
    const newOpeningHours = JSON.parse(JSON.stringify(openingHoursModal));
    if (checked) newOpeningHours[day] = ["00:00", "00:00", "00:00", "00:00"];
    else newOpeningHours[day] = [];
    await setOpeningHoursModal(newOpeningHours);
  };

  const setTimeRush = async (day, timeString, index) => {
    const newRushHours = JSON.parse(JSON.stringify(rushHoursModal));
    newRushHours[day][index] = timeString;
    await setRushHoursModal(newRushHours);
  };

  const dayToggleRush = async (day, checked) => {
    const newRushHours = JSON.parse(JSON.stringify(rushHoursModal));
    if (checked) newRushHours[day] = ["00:00", "00:00", "00:00", "00:00"];
    else newRushHours[day] = [];
    await setRushHoursModal(newRushHours);
  };

  const changeHours = () => {
    let err_flag = 0;
    // const validateHours = hours => {
    //   let valid = true;
    //   Object.keys(hours).map((day) => {
    //     if (hours[day].length !== 0) {
    //       if (
    //         moment(hours[day][0], format) >=
    //         moment(hours[day][1], format) ||
    //         moment(hours[day][2], format) >
    //         moment(hours[day][3], format)
    //       ) {
    //         valid = false
    //       }
    //     }
    //   });
    //   return valid
    // }
    // if (!validateHours(openingHoursModal) || !validateHours(rushHoursModal)) {
    //   message.error(
    //     "L'heure d'ouverture ne peut pas être égale ou supérieure à l'heure de fermeture",
    //     5
    //   );
    //   err_flag = 1;
    // }
    if (err_flag) return;
    let url =
      process.env.REACT_APP_HOST +
      process.env.REACT_APP_UPDATE_PARTNER_DETAILS_API;
    let axiosHeaders = {
      headers: {
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };
    var values = {
      id: localStorage.getItem("mooze_admin_user"),
      opening_hours: openingHoursModal,
      rush_hours: rushHoursModal,
    };
    axios
      .post(url, values, axiosHeaders)
      .catch(function (error) {
        message.error("Erreur lors de la modification des horaires", 5);
        err_flag = 1;
      })
      .then(async (res) => {
        if (err_flag !== 1) {
          message.success("Les horaires ont été modifiés", 2);
          await props.setOpeningHours(openingHoursModal)
        }
      });
  };

  const getTimeValue = (day, index, data) => {
    if (data[day].length === 0 || !data[day][index]) {
      return moment("00:00", format);
    }
    return moment(data[day][index], format);
  }
  return (
    <Modal
      width={800}
      visible={props.showModal}
      className="modify-opening-hours-modal"
      footer={null}
      // centered width={800}
      onCancel={() => props.setShowModal(false)}
    >
      <h1>Sélectionnez vos heures d'ouverture</h1>
      {Object.keys(openingHoursModal).map((day) => (
        <div className="opening-days-day" key={day}>
          <div className="opening-days">
            <h2>{day}</h2>
          </div>
          {[
            {placeholder: "Ouverture", index: 0},
            {placeholder: "Fermeture", index: 1},
            {placeholder: "Ouverture", index: 2},
            {placeholder: "Fermeture", index: 3},
          ].map(({placeholder, index}, key)=>(
            <div key={key}>
              <TimePicker
                type="number"
                bordered={false}
                disabled={openingHoursModal[day].length === 0}
                defaultValue={getTimeValue(day, index, openingHoursModal)}
                placeholder={placeholder}
                format={format}
                allowClear={false}
                onChange={(time, timeString) => {
                  setTime(day, timeString, index);
                }}
              />
            </div>
          ))}
          <div>
            <Switch
              defaultChecked={
                openingHoursModal[day].length !== 0
              }
              onChange={(checked) => {
                dayToggle(day, checked);
              }}
            />
          </div>
        </div>
      ))}
      <br/>
      <h1>Sélectionnez vos heures de rush</h1>
      {Object.keys(rushHoursModal).map((day) => (
        <div className="opening-days-day" key={day}>
          <div className="opening-days">
            <h2>{day}</h2>
          </div>
          {[
            {placeholder: "Ouverture", index: 0},
            {placeholder: "Fermeture", index: 1},
            {placeholder: "Ouverture", index: 2},
            {placeholder: "Fermeture", index: 3},
          ].map(({placeholder, index}, key)=>(
            <div key={key}>
              <TimePicker
                type="number"
                bordered={false}
                disabled={rushHoursModal[day].length === 0}
                defaultValue={getTimeValue(day, index, rushHoursModal)}
                placeholder={placeholder}
                format={format}
                allowClear={false}
                onChange={(time, timeString) => {
                  setTimeRush(day, timeString, index);
                }}
              />
            </div>
          ))}
          <div>
            <Switch
              defaultChecked={
                rushHoursModal[day].length !== 0
              }
              onChange={(checked) => {
                dayToggleRush(day, checked);
              }}
            />
          </div>
        </div>
      ))}
      <Button type="primary" onClick={changeHours}>
        Enregistrer
      </Button>
    </Modal>
  );
};

export default PartnerOpeningHours;
