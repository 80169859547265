import React, { useEffect, useState } from "react";
import { Form, Image, Button, message, Popconfirm } from "antd";
import axios from "axios";

const AllItems = (props) => {
  const [items, setItems] = useState({})
  const [itemsSet, setItemsSet] = useState(false);
  useEffect(() => {
      console.log("CATEGORY PAGE", props);
      let url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_GET_CATEGORY_ITEMS_API;
      let axiosData = {
        params: {
          user_id: localStorage.getItem("mooze_admin_user"),
          category_id: props.categoryId,
        },
        headers: {
          user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
        },
      };
      
      console.log(axiosData)
      axios
      .get(url, axiosData)
      .catch(function (err) {
        message.error("Problème d'obtention des articles", 5);
      })
      .then(async (res) => {
        console.log(res);
        await setItems({});
        await setItems(res.data);
        await setItemsSet(true);
      });
    }, [props.categoryId]);

    const deleteItem = (item) =>{
      let url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_DELETE_CATEGORY_ITEM_API;
    let values = {
      user_id: localStorage.getItem("mooze_admin_user"),
      category_id: props.categoryId,
      item_id: item
    };
    let axiosHeaders = {
      headers: {
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };
    axios
      .post(url, values, axiosHeaders)
      .catch(function (error) {
        message.error("Erreur lors de la suppression de la item", 3);
      })
      .then(async (res) => {
        var newItems = JSON.parse(JSON.stringify(items))


        delete newItems[item]
        await setItems(newItems);
      });
    }


  if(!itemsSet) return null;
  return (
    <div className="all-items-page">
      <h1 className="all-items-page-title">
        <div>{props.category.name}</div>
        <Button
          onClick={() => {
            props.setPageView(1);
          }}
          type="primary"
          className="add-category-item-btn"
        >
          Ajouter
        </Button>
      </h1>
      <div className="items-list">
        {Object.keys(items).map((item) => (
          <div className="items-list-item" key={item}>
            <div className="items-list-icon-name">
            <Image 
                preview={false}
                src = {items[item].photo}></Image>
            <div className="items-list-item-name">
              {items[item].name}
            </div>
            </div>
            <div className="items-list-buttons">
              <Button className="modify-item"
              onClick={async () => {
                console.log("ITEM item", items[item])
                await props.setItemId(item);
                await props.setItem(items[item]);
                await props.setPageView(2);
              }}
              >Modify</Button>
              &nbsp;&nbsp;
              <Popconfirm
                placement="right"
                title="Supprimer cette item?"
                onConfirm={() => {
                  deleteItem(item);
                }}
              >
                <Button className="delete-item">Supprimer</Button>
              </Popconfirm>
            </div>
          </div>
        ))}
      </div>
   
    </div>
  );
};

export default AllItems;
