import React from "react";
import { Component } from "react";
import { Form, Input, Select, message, Button, Image, Radio } from "antd";
import axios from "axios";
const { Option } = Select;

class CreateCategory extends Component {
  state = {
    icons: [],
    selected_icon_url: "",
    search: "",
  };

  constructor(props) {
    super(props);
    let url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_GET_CATEGORY_ICONS_API;
    axios
      .get(url)
      .catch(function (error) {
        message.error(
          "Erreur lors de la récupération des icônes de catégorie",
          5
        );
      })
      .then(async (res) => {
        await this.setState({
          icons: res.data,
        });
      });
  }

  async setSelectedIcon(icon) {
    console.log(icon);
    await this.setState({
      selected_icon_url: icon.image,
    });
  }

  addCategory(values) {
    if (this.state.selected_icon_url === "") {
      message.error("Veuillez sélectionner une icône pour cette catégorie", 3);
      return;
    }
    var axiosValues = {
      id: localStorage.getItem("mooze_admin_user"),
      type: localStorage.getItem("mooze_admin_user_type_id") === '2' ? values.category_type : "butcher",
      name: values.name,
      icon_url: this.state.selected_icon_url,
    };
    let axiosHeaders = {
      headers: {
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };
    let url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_ADD_CATEGORY_API;
    axios
      .post(url, axiosValues, axiosHeaders)
      .catch(function (error) {
        message.error("Erreur lors de l'ajout de la catégorie", 3);
      })
      .then(async (res) => {
        console.log("Category added", res);
        var newCategory = this.props.categories;
        await console.log("NEW CAT", newCategory);
        newCategory[res.data.category_id] = {
          name: res.data.name,
          icon: res.data.icon,
          type: axiosValues.type
        };
        await this.props.changeCategories(newCategory);
        await this.props.changePageView();
      });
  }

  searchBar(search) {
    this.setState({ search: search.target.value });
  }

  render() {
    return (
      <div className="add-categories-page">
        <h2>
          <p className="add-categories-title">Ajouter une catégorie</p>
        </h2>
        <Form
          className="add-category-form"
          name="add-category-form"
          onFinish={this.addCategory.bind(this)}
        >
          {localStorage.getItem("mooze_admin_user_type_id") === '2' ?
         ( <Form.Item
          className="add-category-type"
          name="category_type"
          rules={[{ required: true, message: "Ceci est nécessaire" }]}
          >
            <Select placeholder="Choisir modèle de list">
              <Option value="menu">Menu</Option>
              <Option value="plat">Plat</Option>
            </Select>
          </Form.Item>)
          : null}
          <Form.Item
            className="add-category-name"
            name="name"
            rules={[{ required: true, message: "Ceci est nécessaire" }]}
          >
            <Input placeholder="Nom de la catégorie" />
          </Form.Item>
          <h2 className="add-category-icon-subtitle">sélectionnez une icône</h2>
          <br></br>
          <div className="add-category-icons">
            <Input
              className="add-category-search"
              placeholder="Rechercher"
              onChange={this.searchBar.bind(this)}
            ></Input>
            <div className="add-category-icons-list">
              <Radio.Group defaultValue="none">
                {this.state.icons.map((icon) => (
                  <div key={icon._id}>
                    {icon.name
                      .toLowerCase()
                      .indexOf(this.state.search.toLowerCase()) >= 0 ? (
                      <div
                        
                        className="add-category-icons-list-icon"
                      >
                        <Radio value={Math.random()}>
                          <Image
                            onClick={() => {
                              this.setSelectedIcon(icon);
                            }}
                            src={icon.image}
                            width={50}
                            preview={false}
                          ></Image>
                        </Radio>
                      </div>
                    ) : null}
                  </div>
                ))}
              </Radio.Group>
            </div>
          </div>
          <br></br>
          <Button
            type="primary"
            htmlType="submit"
            className="add-category-add-btn"
          >
            Ajouter
          </Button>
          <br></br>
          <br></br>
          <Button
            type="primary"
            className="add-category-cancel-btn"
            onClick={this.props.changePageView}
          >
            Annuler
          </Button>
        </Form>
      </div>
    );
  }
}

export default CreateCategory;
