import React, { useState } from 'react';
import './style.css';

export default function Dropdown(props) {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState('');

  const onSelect = (option) => {
    setShow(false);
    setValue(option.title);
    props.onSelect(option);
  };
  return (
    <div className={show ? 'custom-dropdown-container custom-dropdown-container-open' : 'custom-dropdown-container'} onClick={() => setShow(!show)}>
      <span className="custom-dropdown-title">{value ? value : props.title}</span>

      <div className="custom-dropdown-item-wrapper">
        {
          show ? props.options.map(option => {
            return (
              <div className="custom-dropdown-item" onClick={() => onSelect(option)}>
                {option.title}
              </div>
            );
          }) : null
        }
      </div>
    </div>
  );
};
