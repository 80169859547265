import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import './styles.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter, Switch, useHistory, Redirect, useParams } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Login from './Login/Login';
import AdminArea from './MoozeAdmin/AdminArea';
import AdminLogin from './MoozeAdmin/AdminLogin';
import PartnerDashboard from './MoozePartner/PartnerDashboard';
import NewPasswordForm from './Login/NewPasswordForm';
import axios from 'axios';
import CollectPayment from './Payment/CollectPayment';

var loggedIn = localStorage.getItem('mooze_admin_jwt');

function setLoggedOut() {
  loggedIn = localStorage.getItem('mooze_admin_jwt');
  console.log(loggedIn);
}

ReactDOM.render(
  (
    <BrowserRouter>
      <Switch>
        <Route exact path="/add-card">
          <CollectPayment page={'add-card'}/>
        </Route>
        <Route exact path="/payment">
          <CollectPayment page={'payment'}/>
        </Route>
        <Route exact path="/">
          {loggedIn ? (localStorage.getItem("mooze_admin_user_type_id") === '1' ? <Redirect to="/adminArea" /> : <Redirect to="/partnerDashboard" />) : <Login />}
        </Route>
        <Route exact path="/login">
          {loggedIn ?
            (localStorage.getItem("mooze_admin_user_type_id") === '1' ? <Redirect to="/adminArea" /> : <Redirect to="/partnerDashboard" />) : <Login />}
        </Route>
        <Route exact path="/adminLogin">
          {loggedIn ? <Redirect to="/adminArea" /> : <AdminLogin />}
        </Route>
        <Route exact path="/verify/:id/:utid">
          <NewPasswordForm />
        </Route>
        <ProtectedRoute exact path="/adminArea" component={AdminArea} setLoggedOut={setLoggedOut} />
        <ProtectedRoute exact path="/partnerDashboard" component={PartnerDashboard} setLoggedOut={setLoggedOut} />

      </Switch>
    </BrowserRouter>
  ),
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
