import React, { useEffect, useState } from "react";
import { Form, InputNumber, Input, Image, message, Button, Upload } from "antd";
import axios from "axios";
import { CloseCircleFilled } from "@ant-design/icons";
/**
 *
 * useEffect to get all the sauces from the ref data
 * render them in the view on the left
 * render my sauces on the view in the right
 */

const Sauces = (props) => {
  const [sauces, setSauces] = useState([]);
  const [mySauces, setMySauces] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const [search, setSearch] = useState("");
  const [initialValues, setInitialValues] = useState({});

  const addToMySauce = async (sauce) => {
    let _mySauces = JSON.parse(JSON.stringify(mySauces));
    console.log(_mySauces);
    sauce = JSON.parse(JSON.stringify(sauce));
    console.log(sauce._id);
    let i = 0;
    while (i < _mySauces.length) {
      if (sauce._id === _mySauces[i]._id) return;
      i++;
    }
    _mySauces.push(sauce);
    await setMySauces(_mySauces);
  };

  const removeFromMySauces = async (sauce) => {
    console.log("called close");
    let _mySauces = JSON.parse(JSON.stringify(mySauces));
    let _new = [];
    let i = 0;
    while (i < _mySauces.length) {
      if (sauce._id !== _mySauces[i]._id) _new.push(_mySauces[i]);
      i++;
    }
    await setMySauces(_new);
  };

  const searchBar = (search) => {
    console.log(search.target.value);
    setSearch(search.target.value);
  };

  const updateMySauces = (values) => {
    console.log(values);
    let keys = Object.keys(values);
    let _values = Object.values(values);
    let _mySauces = mySauces;
    let i = 0;
    while (i < keys.length) {
      _mySauces[i].price = _values[i];
      i++;
    }
    console.log(_mySauces);
    let url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_UPDATE_MY_EXTRAS;
    let body = {
      id: localStorage.getItem("mooze_admin_user"),
      extra_type: "sauce",
      sauces: _mySauces,
    };
    axios
      .post(url, body)
      .catch(function (error) {
        message.error("Error in saving sauces. Try again");
      })
      .then(async (res) => {
        message.success("Sauces Saved successfully", 2);
      });
  };

  useEffect(() => {
    console.log("props", props.sauces.sauces);
    let _mySauces = [];
    let _initialValues = {};

    let _url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_GET_CATEGORIES_API;

    let axiosData = {
      params: { id: localStorage.getItem("mooze_admin_user") },
      headers: {
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };
    axios
      .get(_url, axiosData)
      .catch(function (err) {
        message.error("Problème d'obtention des catégories", 5);
      })
      .then(async (res) => {
        console.log(res.data.sauces);
        if (res.data.sauces){
	setMySauces(res.data.sauces);
        if (res.data.sauces.length) {
          let i = 0;
          while (i < res.data.sauces.length) {
            _initialValues[res.data.sauces[i]._id] = res.data.sauces[i].price;
            i++;
          }
          setInitialValues(_initialValues);
          console.log("IV: ", _initialValues);
        }

      	}
        // console.log(this.state.categories);
     	 });

    let url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_GET_REFERENCE_DATA;
    console.log(url);
    let params = { category: "sauces" };
    axios
      .get(url, { params: params })
      .catch(function (error) {
        console.log(error);
      })
      .then(async (res) => {
        console.log(res);
        setSauces(res.data);
        setIsSet(true);
      });
  }, []);

  if (!isSet) return null;
  return (
    <div>
      <div className="sauces">
        <div className="sauces-header">
          <h1 className="sauces-header">Sauces</h1>
          <h1 className="sauces-header">Mes Sauces</h1>
        </div>
        <div className="sauces-body">
          <div className="all-sauces">
            <Input
              className="sauces-search"
              placeholder="Rechercher"
              onChange={(value) => searchBar(value)}
            ></Input>
            <div className="sauces-list">
              <div className="sauces-list-wrap">
                {sauces.map((sauce) => (
                  <div key={sauce._id} onClick={() => addToMySauce(sauce)}>
                    {sauce.name.toLowerCase().indexOf(search.toLowerCase()) >=
                    0 ? (
                      <div className="sauce-icon">
                        <div align="center" className="sauce-icon-image">
                          <Image src={sauce.image} preview={false} width={60} />
                        </div>
                        <div align="center" className="sauce-icon-title">
                          {sauce.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="my-sauces">
            <Form
              name="my-sauces-form"
              onFinish={updateMySauces}
              className="my-sauces-form"
              initialValues={initialValues}
            >
              <div className="my-sauces-list">
                <div className="my-sauces-wrap">
                  {mySauces && mySauces.map((sauce) => (
                    <div className="selected-sauce" key={sauce._id}>
                      <div key={sauce._id} className="sauce-icon">
                        <div align="center" className="sauce-icon-image">
                          <Image src={sauce.image} preview={false} width={60} />
                        </div>
                        <div align="center" className="sauce-icon-title">
                          {sauce.name}
                        </div>
                      </div>
                      <div>
                        <Form.Item name={sauce._id}>
                          <Input
                            type="number"
                            bordered={false}
                            className="sauce-price"
                            step={0.01}
                            min={0}
                            suffix="€"
                            required={true}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Button
                          className="delete-sauce"
                          icon={<CloseCircleFilled />}
                          onClick={() => removeFromMySauces(sauce)}
                        ></Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div align="center" className="my-sauces-button">
                <Button htmlType="submit">Save</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sauces;
