import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Image,
  message
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import Img from '../assets/logo.png';

import axios from "axios";

const NewPasswordForm = () => {

  const history = useHistory();

  const [response, setResponse] = useState(0);
  const [utid, setUtid] = useState(-1);

  const params = useParams();
  
  useEffect(() => {
  console.log("param", params);
  axios.get(process.env.REACT_APP_HOST + "/verify?ref=" + params.id + "&utid=" + params.utid).then((res) => {
    const response = res.data;
    console.log(response);
    setResponse(response);
    setUtid(params.utid)
  }).catch((err)=> {
    console.log(err);
  });
},[])

  const onFinish = (values) => {
    let axiosHeaders = {
      headers: {
        'user_type_id': utid,
      }
    }
    let data = {
      email: response.email,
      password: values.password,
      id: response.id
    }
    if(response.proTab)
      data.proTab = true
    console.log('head: ', axiosHeaders);
    axios
      .post(process.env.REACT_APP_HOST + "/newPassword", data, axiosHeaders 
      )
      .then((res) => {
        message.success("Password reset successfully!", 5);
        history.push("/")
      });
  };


  if (response === 0)
  {
    // history.push("/")
    return null;
  }
  return (

    <div className="new-password-page" style={{ display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
      <div>
      <Image
          width={200}
          src={Img}
          preview={false}
        />
      </div>
        <br></br>
        <br></br>

        <div className="reset-password-form-div">
          <h1 align="center">Set new password</h1>
          <Form
            name="reset_password"
            className="reset-password-form"
            initialValues={{}}
            onFinish={onFinish}
          >
                <Form.Item name="email" >
                  <Input disabled={true} defaultValue={response.email}></Input>
                </Form.Item>
            <Form.Item
              name="password"
              placeholder="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
            <Input.Password placeholder="Password"/>
            </Form.Item>
            <Form.Item
              name="confirm"
              placeholder="Confirm password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject("Passwords do not match!");
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Password"/>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="change-password-button"
              >
                Change Password
            </Button>
            </Form.Item>
          </Form>
        </div>
    </div>
  );
};

export default NewPasswordForm;
