import React, { useEffect } from "react";
import { Component } from "react";
import { Form, Image, Button, message, Popconfirm } from "antd";
import axios from "axios";

const AllCategories = (props) => {
  useEffect(() => {
    let isMounted = true;
  }, []);

  const deleteCategory = (category_id) => {
    let url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_DELETE_CATEGORY_API;
    let values = {
      user_id: localStorage.getItem("mooze_admin_user"),
      category_id: category_id,
    };
    let axiosHeaders = {
      headers: {
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };
    axios
      .post(url, values, axiosHeaders)
      .catch(function (error) {
        message.error("Erreur lors de la suppression de la catégorie", 3);
      })
      .then(async (res) => {
        var newCategories = props.categories;

        console.log("newCat BEF", newCategories);

        // let newCategories = this.props.categories;
        delete newCategories[category_id];
        console.log("newCat AFT", newCategories);
        await props.changeCategories(newCategories);
      });
  };

  const modifyCategory = async (category_id, category_icon, category_name) => {
    console.log("Modify category called", category_id);
    var category = {
      id: category_id,
      name: category_name,
      icon: category_icon,
    };
    props.setSelectedCategory(category);
    await props.changeModifyCategory();
    console.log(props.modifyCategory);
    await props.changePageView();
  };

  return (
    <div className="all-categories-page">
      <h1 className="categories-title">
        Mes categories &nbsp;&nbsp;
        <Button
          onClick={props.changePageView}
          type="primary"
          className="add-category-btn"
        >
          Ajouter
        </Button>
      </h1>
      <div className="categories-list">
        {Object.keys(props.categories).map((category) => (
          <div key={category}>
            {props.categories[category].type === "sauces" ||
            props.categories[category].type === "boissons" ? null : (
              <div className="categories-list-category">
                <div className="categories-list-icon-name">
                  <Image
                    preview={false}
                    src={props.categories[category].icon}
                  ></Image>
                  <div className="category-list-category-name">
                    {props.categories[category].name}
                  </div>
                </div>
                <div className="category-list-buttons">
                  <Button
                    className="modify-category"
                    onClick={() => {
                      modifyCategory(
                        category,
                        props.categories[category].icon,
                        props.categories[category].name
                      );
                    }}
                  >
                    Modify
                  </Button>
                  &nbsp;&nbsp;
                  <Popconfirm
                    placement="right"
                    title="Supprimer cette catégorie?"
                    onConfirm={() => {
                      deleteCategory(category);
                    }}
                  >
                    <Button className="delete-category">Supprimer</Button>
                  </Popconfirm>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllCategories;
