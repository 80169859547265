import React from 'react'
import { Redirect } from 'react-router-dom'

class ProtectedRoute extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const Component = this.props.component;
        const props = this.props;
        const isAuthenticated = localStorage.getItem('mooze_admin_jwt');

        return isAuthenticated ?
            (
                (this.props.path === "/partnerDashboard" && localStorage.getItem("mooze_admin_user_type_id") !== '1'
                    || this.props.path === "/adminArea" && localStorage.getItem("mooze_admin_user_type_id") === '1') ?
                    (
                        <Component props={this.props} />
                    ) :
                    (
                        <Redirect to={{ pathname: '/login' }} />
                    )
            )
            : (
                <Redirect to={{ pathname: '/login' }} />
            );
    }
}

export default ProtectedRoute;