import React from "react";
import { Component } from "react";

class OnboardingSuccess extends Component {

    render() {
        return (
            <div className="login__onboardingSuccess">
                <h1>
                    <p className="onboarding_success_title_one">Votre inscription a été validée reçu!</p>
                    <p className="onboarding_success_title_two">Un conseiller va vous contacter rapidement</p>
                </h1>
                <h4>
                <p className="onboarding_success_subtitle"> Pour toute question <strong>support@moozeyourphone.com</strong></p>
                </h4>
            </div>
        )
    }

}

export default OnboardingSuccess;