import React from "react";
import { Component } from "react";
import { Form, Input, Button, Image, Select, message } from 'antd';

import HomeLOGO from '../assets/home_image.png';
import { Anchor } from 'antd';
import axios from 'axios';
import { useHistory } from "react-router-dom";

const { Option } = Select;
const { Link } = Anchor;


const LoginForm = (props) => {

    const history = useHistory();

    message.config({
        maxCount: 1
    })


    const loadLocalStorage = (data) => {

        localStorage.setItem('mooze_admin_jwt', data.accessToken);
        localStorage.setItem('mooze_admin_user', data.id);
        localStorage.setItem('mooze_admin_user_type_id', data.user_type_id);
        history.push("/partnerDashboard")
    }

    const onFinish = (values) => {
        message.loading('Please wait...', 1);
        let url = process.env.REACT_APP_HOST + process.env.REACT_APP_PARTNER_LOGIN;
        let user_type_id;
        console.log(values);
        console.log(url)
        if (values.partner_type === 'butcher')
            user_type_id = 3;
        else if (values.partner_type === 'restaurant')
            user_type_id = 2;
        let axiosHeaders = {
            headers: {
                'user_type_id': user_type_id,
            }
        }
        axios.post(url, values, axiosHeaders).then((res) => {
            console.log(res);
            message.success('Login successful!', 3);
            loadLocalStorage(res.data);

        }).catch(function (error) {
            // if (JSON.stringify(error).indexOf("401") >= 0);
            message.error('Invalid Credentials!', 3)
        })
    };
    const handleChange = (value) => {
        console.log(value);
    }


    return (
        <div className="login__loginForm">
            <div className="login__loginForm__form">
                <div className="login__loginForm__homelogo">
                    <Image
                        preview={false}
                        width={300}
                        src={HomeLOGO}
                    />
                </div>

                <Form
                    name="partner_login"
                    className="partner_loginForm"
                    // initialValues={ }
                    onFinish={onFinish.bind(this)}
                >
                    <Form.Item
                        name="partner_type" rules={[{ required: true, message: "Ceci est nécessaire" }]}
                    >
                        <Select placeholder="Type de établissement" onChange={handleChange.bind(this)}>
                            <Option value="restaurant">Restaurant</Option>
                            <Option value="butcher">Butcher</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Ceci est nécessaire",
                            }
                        ]}
                    >
                        <Input
                            type="email"
                            placeholder="Email"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Ceci est nécessaire",
                            }
                        ]}
                    >
                        <Input type="password" placeholder="Mot de Passe" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="partner_loginForm_button">
                            Connexion
                        </Button>
                    </Form.Item>
                </Form>
                <div className="login__forgotPassword">
                    <Anchor affix={false} onClick={props.showForgotPasswordForm}>
                        <Link href="#" title="Mot de Passe oublié?" />
                    </Anchor>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;