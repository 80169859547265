import React from "react";
import axios from "axios";
import { Form, Input, Select, message, Button, Upload } from "antd";



const StripeButton = (props) => {

    const { partnerDetails } = props;

    const initateSetup = () => {

        console.log(partnerDetails)
        message.loading('Please wait...')
        var error_flag = 0;
        let url =
            process.env.REACT_APP_HOST +
            process.env.REACT_APP_STRIPE_SETUP_API;
        // let axiosData = {
        let headers = {
            headers: {
                user_type_id: (partnerDetails.partner_type === 'restaurant') ? 2 : 3
            }
        };
        let body = {
            _id: partnerDetails._id,
            email: partnerDetails.email_admin
        }
        // };

        axios.post(url, body, headers)
            .catch(function (e) {
                message.error("Erreur lors de l'obtention des détails du compte", 5);
                error_flag = 1;
            }).then(async (res) => {
                if (!error_flag) {
                    console.log(res);
                    window.location.replace(res.data.url);
                }
            })
    }

    return (
        <div className="connect-to-stripe" onClick={() => { initateSetup() }}>
            <a href="#" className="stripe-connect"><span>Connect with</span></a>
        </div>
    )
};

export default StripeButton;