import React, { useEffect, useState } from "react";
import { Form, InputNumber, Input, Image, message, Button, Select } from "antd";
import axios from "axios";
import { CloseCircleFilled } from "@ant-design/icons";
const { Option } = Select;

const Boissons = () => {
  const [boissons, setBoissons] = useState([]);
  const [myBoissons, setMyBoissons] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const [search, setSearch] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const [boissonCategory, setBoissonCategory] = useState("");

  const addToMyBoisson = async (boisson) => {
    let _myBoissons = JSON.parse(JSON.stringify(myBoissons));
    console.log(_myBoissons);
    boisson = JSON.parse(JSON.stringify(boisson));
    console.log(boisson._id);
    let i = 0;
    while (i < _myBoissons.length) {
      if (boisson._id === _myBoissons[i]._id) return;
      i++;
    }
    _myBoissons.push(boisson);
    await setMyBoissons(_myBoissons);
  };

  const removeFromMyBoissons = async (boisson) => {
    console.log("called close");
    let _mySauces = JSON.parse(JSON.stringify(myBoissons));
    let _new = [];
    let i = 0;
    while (i < _mySauces.length) {
      if (boisson._id !== _mySauces[i]._id) _new.push(_mySauces[i]);
      i++;
    }
    await setMyBoissons(_new);
  };

  const searchBar = (search) => {
    console.log(search.target.value);
    setSearch(search.target.value);
  };

  const updateMyBoissons = (values) => {
    console.log('upd', values);
    let keys = Object.keys(values);
    let _values = Object.values(values);
    let _myBoissons = myBoissons;
    let i = 0;
    while (i < keys.length) {
      _myBoissons[i].price = _values[i];
      i++;
    }
    let url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_UPDATE_MY_EXTRAS;
    let body = {
      id: localStorage.getItem("mooze_admin_user"),
      extra_type: "boissons",
      boissons: _myBoissons,
    };
    axios
      .post(url, body)
      .catch(function (error) {
        message.error("Error in saving sauces. Try again");
      })
      .then(async (res) => {
        message.success("Sauces Saved successfully", 2);
      });
  };

  useEffect(() => {
    let _initialValues = {};

    let _url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_GET_CATEGORIES_API;
    console.log(_url);
    let axiosData = {
      params: { id: localStorage.getItem("mooze_admin_user") },
      headers: {
        user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
      },
    };
    axios
      .get(_url, axiosData)
      .catch(function (err) {
        message.error("Problème d'obtention des catégories", 5);
      })
      .then(async (res) => {
        console.log("biossons: ", res.data);
        if (res.data.boissons) {
          setMyBoissons(res.data.boissons);
          if (res.data.boissons.length) {
            let i = 0;
            while (i < res.data.boissons.length) {
              _initialValues[res.data.boissons[i]._id] =
                res.data.boissons[i].price;
              i++;
            }
            setInitialValues(_initialValues);
            console.log("IV: ", _initialValues);
          }
        }

        // console.log(this.state.categories);
      });

    let url =
      process.env.REACT_APP_HOST + process.env.REACT_APP_GET_REFERENCE_DATA;
    let params = { category: "drinks" };
    axios
      .get(url, { params: params })
      .catch(function (error) {
        console.log(error);
      })
      .then(async (res) => {
        console.log(res);
        setBoissons(res.data);
        setIsSet(true);
      });
  }, []);

  if (!isSet) return null;
  return (
    <div>
      <div className="sauces">
        <div className="sauces-header">
          <h1 className="sauces-header">Boissons</h1>
          <h1 className="sauces-header">Mes Boissons</h1>
        </div>
        <div className="sauces-body">
          <div className="all-sauces">
            <Input
              className="sauces-search"
              placeholder="Rechercher"
              onChange={(value) => searchBar(value)}
            ></Input>
            <div className="boisson-search-dropdown">
              <Select
                placeholder="Type d'boisson"
                onChange={(value) => setBoissonCategory(value)}
              >
                <Option value="">Toute</Option>
                <Option value="canned_soda">Canettes Soda</Option>
                <Option value="bottled_soda">Bouteilles Soda</Option>
                <Option value="water">Eaux</Option>
                <Option value="juice">Jus</Option>
                <Option value="other">Autres Boissons</Option>
              </Select>
            </div>

            <div className="sauces-list">
              <div className="sauces-list-wrap">
                {boissons.map((boisson) => (
                  <div
                    key={boisson._id}
                    onClick={() => addToMyBoisson(boisson)}
                  >
                    {boisson.name.toLowerCase().indexOf(search.toLowerCase()) >=
                      0 &&
                    boisson.subcategory
                      .toLowerCase()
                      .indexOf(boissonCategory.toLowerCase()) >= 0 ? (
                      <div className="sauce-icon">
                        <div align="center" className="sauce-icon-image">
                          <Image
                            src={boisson.image}
                            preview={false}
                            width={60}
                          />
                        </div>
                        <div align="center" className="sauce-icon-title">
                          {boisson.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="my-sauces">
            <Form
              name="my-sauces-form"
              className="my-sauces-form"
              onFinish={updateMyBoissons}
              initialValues={initialValues}
            >
              <div className="my-sauces-list">
                <div className="my-sauces-wrap">
                  {myBoissons.map((sauce) => (
                    <div className="selected-sauce" key={sauce._id}>
                      <div key={sauce._id} className="sauce-icon">
                        <div align="center" className="sauce-icon-image">
                          <Image src={sauce.image} preview={false} width={60} />
                        </div>
                        <div align="center" className="sauce-icon-title">
                          {sauce.name}
                        </div>
                      </div>
                      <div>
                        <Form.Item name={sauce._id}>
                        <Input
                            type="number"
                            bordered={false}
                            className="sauce-price"
                            step={0.01}
                            min={0}
                            suffix="€"
                            required="true"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Button
                          className="delete-sauce"
                          icon={<CloseCircleFilled />}
                          onClick={() => removeFromMyBoissons(sauce)}
                        ></Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div align="center" className="my-sauces-button">
                <Button htmlType="submit">Save</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boissons;
