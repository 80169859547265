import React from "react";
import { Component } from "react";
import { Layout, Menu, Image, message } from 'antd';
import CreatePartner from "./CreatePartner";
import HandleAdmins from "./HandleAdmins";
import Img from "../assets/moozeforbusiness.png"
import AdminDashboard from "./AdminDashboard";
import ReferenceData from "./ReferenceData";

const { Header, Content, Footer, Sider } = Layout;

class AdminArea extends Component {

    constructor(props) {
        super(props)
        message.config({
            maxCount: 1
        })
    }

    state = {
        key: "dashboard"
    }

    logout() {
        console.log("Logout called");
        message.loading('Please wait...', 2);
        localStorage.removeItem('mooze_admin_jwt');
        localStorage.removeItem('mooze_admin_user');
        localStorage.removeItem('mooze_admin_user_type_id');
        message.success('Logged out!', 3);
        window.location.reload();
    }

    changeKey(key) {
        this.setState({
            key: key
        })
    }
    setSwitch(key) {
        switch (key) {
            case 'admin':
                return <HandleAdmins />
            case 'creer':
                return <CreatePartner changeKey={this.changeKey.bind(this)} />
            case 'dashboard':
                return <AdminDashboard />
            case 'ref_data':
                return <ReferenceData />
            case 'deconnexion':
                this.logout();
        }
    }
    render() {
        return (
            <Layout className="admin-area">
                <Sider
                    width={300}
                >
                    <div className="mooze-business-logo">
                        <Image className="mooze-logo-image" src={Img} alt="mooze for business" preview={false}></Image>
                    </div>
                    <Menu selectedKeys={this.state.key} onClick={(value) => { this.setState({ key: value.key }) }}>
                        <Menu.Item key="admin">
                            Admin
                        </Menu.Item>
                        <Menu.Item key="creer">
                            Creer
                        </Menu.Item>
                        <Menu.Item key="dashboard">
                            Dashboard
                        </Menu.Item>
                        <Menu.Item key="ref_data">
                            Add Stock Images
                        </Menu.Item>
                        <Menu.Item key="deconnexion">
                            Deconnexion
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout>
                    <Content className={this.state.key === "creer" ? "admin-area-content-creer" : "admin-area-content"}>
                        {this.setSwitch(this.state.key)}
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default AdminArea;