import React from "react";
import {Col, Row, TimePicker} from "antd";
import moment from "moment";


const EstimationTime = (props) => {
  const {setEstimationTime, estimationTime, format} = props;
  const setTime = (field, timeString) => {
    setEstimationTime({
      ...estimationTime,
      [field]: timeString,
    });
  };
  return (
    <div className="estimation-time">
      <h3>
        Le temps de préparation permet <br/>
        d 'estimer un temps d'attente au client
      </h3>
      <h4><span className={'title-text'}>Temps de préparation</span></h4>
      <Row>
        {[
          {title: 'Hors rush', defaultValue: estimationTime.rush_off, field: 'rush_off'},
          {title: 'Rush', defaultValue: estimationTime.rush, field: 'rush'},
        ].map(({title, defaultValue, field}) => {
          console.log('estimationTime map', estimationTime);

          return <Col span={12} key={field}>
            <div><span className={'title-text'}>{title}</span></div>
            <div className="estimation-time-picker">
              <TimePicker
                type="number"
                bordered
                disabled={false}
                defaultValue={moment(defaultValue, format)}
                placeholder={''}
                format={format}
                allowClear={false}
                value={moment(defaultValue, format)}
                onChange={(time, timeString) => {
                  setTime(field, timeString);
                }}
              />
            </div>
          </Col>
        })}
      </Row>
    </div>
  );
};

export default EstimationTime;
