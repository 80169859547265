import React, { useEffect, useState } from "react";
import axios from 'axios'
import { message, Divider } from "antd";
import moment from "moment";
import {
   ArrowDownOutlined,
   ArrowUpOutlined
  } from "@ant-design/icons";

  const Orders = () => {
    const [orders, setOrders] = useState([])
    const [isSet, setIsSet] = useState(false)
    const [isAscending, setIsAscending] = useState(false)
    useEffect(() => {
        let url = process.env.REACT_APP_HOST + process.env.REACT_APP_GET_ORDERS_API
        let axiosData = {
            params: { partner_id: localStorage.getItem("mooze_admin_user") },
        };
        axios
            .get(url, axiosData)
            .catch((err) => {
                message.error("Problème d'obtention des commandes", 5);
            })
            .then(async (result) => {
                console.log("ORDERS ARE", result?.data)
                await setOrders(result?.data.sort((a, b) => b.user_time_slot - a.user_time_slot))
                await setIsSet(true)
            })
    }, [])

    const showStatus = (order) => {
        if(order.order_status === "completed")
            return "Livrée"
        else if(order.order_status === "ready")
            return "Prêt"
        else if(order.order_status === "cancelled")
            return "Annulé"
            else if(order.order_status === "active")
            return "Actif"
        else if(order.order_status === "placed")
        {
            if(order.payment_status && order.payment_status === "failure")
                return "Échouer"
            return "Payée"
        }
    }

    const getDate = (date) => {
        return moment(date).format("DD-MM-YYYY")
    }

    const sortDateAscending = async (setAscending) => {
        var newOrders = JSON.parse(JSON.stringify(orders))
        if(setAscending)
        {
            await setOrders(newOrders.sort((a, b) => a.user_time_slot - b.user_time_slot))
            await setIsAscending(true)
        }
        else
        {
            await setOrders(newOrders.sort((a, b) => b.user_time_slot - a.user_time_slot))
            await setIsAscending(false)
        }
    }
    if (!isSet) return null

    return (
        <div className="partner-orders-page">
            <div className="partner-orders-header">
                <div className="partner-orders-header-names">Statut</div>
                <div className="partner-orders-header-names">Date {isAscending? <ArrowUpOutlined onClick = {()=> sortDateAscending(false)}/> : <ArrowDownOutlined onClick = {()=> sortDateAscending(true)}/>}</div>
                <div className="partner-orders-header-names">Client</div>
                <div className="partner-orders-header-names">Total (en €)</div>
            </div>
            {
                orders.map((order, index) => (
                    <div key={index}>
                        <div
                            className="partner-orders-data">
                            <div className="partner-orders-data-individual">{showStatus(order)}</div>
                            <div className="partner-orders-data-individual">{getDate(order.user_time_slot)}</div>
                            <div className="partner-orders-data-individual">{order.user_firstname + " " + order.user_lastname}</div>
                            <div className="partner-orders-data-individual">{order.price}</div>
                        </div>
                        <Divider />
                    </div>
                ))
            }
        </div>
    )
}

export default Orders
