import React from "react";
import { Component } from "react";
import LoginForm from './LoginForm';
import OnboardingForm from './OnboardingForm';
import ForgotPasswordForm from './ForgotPasswordForm.js';
import OnboardingSuccess from './OnboardingSuccess';
import { Image } from 'antd';
import MFBLogo from '../assets/moozeforbusiness.png';


class Login extends Component {

    constructor(props)
    {
        super(props)
    }

    state = {
        onboardingFormSubmitted: false,
        forgotPasswordForm: false
    }

    showForgotPasswordForm() {
        this.setState({
            forgotPasswordForm: true
        })
    }

    onboardingRequestSuccessful() {
        this.setState({
            onboardingFormSubmitted: true
        })
    }

    render() {
        return (
            <div className="login">
                <div className="login__loginForm__mfblogo">
                    <Image
                        preview={false}
                        width={400}
                        src={MFBLogo}
                    />
                </div>
                { this.state.onboardingFormSubmitted ? <OnboardingSuccess /> :(
                    this.state.forgotPasswordForm ? <ForgotPasswordForm/> :
                    <LoginForm
                        showForgotPasswordForm={this.showForgotPasswordForm.bind(this)}
                    />
                    )}
                <OnboardingForm
                    onboardingFormSubmitted={this.state.onboardingFormSubmitted}
                    onboardingRequestSuccessful={this.onboardingRequestSuccessful.bind(this)}
                />
            </div>
        )
    }
}

export default Login;
