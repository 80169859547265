import React from "react";
import { Component } from "react";
import { Form, Input, Button, Select, message, Checkbox } from 'antd';
import axios from 'axios';

const { Option } = Select;

class CreatePartner extends Component {

    constructor(props) {
        super(props);
        console.log('props: ', props);
    }

    state = {
        showDownPayment: false,
        showAlreadyExists: false,
        sponsored: false,
    }

    async onSponsor() {
        if (this.state.sponsored)
            await this.setState({
                sponsored: false,
            })
        else
            await this.setState({
                sponsored: true,
            })
        console.log(this.state.sponsored);
    }

    onFinish(values) {
        let user_type_id;
        if (values.partner_type === 'restaurant')
            user_type_id = 2
        else if (values.partner_type === 'butcher')
            user_type_id = 3
        let headers = {
            user_type_id: user_type_id
        }
        values.is_sponsored = this.state.sponsored;
        let url = process.env.REACT_APP_HOST + process.env.REACT_APP_CREATE_PARTNERAPI
        axios.post(url, values, {
            headers: headers
        }).catch(function (error) {
            console.log(error);
        }).then((res) => {
            console.log(res.data);
            if (res.data.error_code === process.env.REACT_APP_MONGO_ERROR_CODE_DUPLICATE_KEY)
                message.error('Oops, Looks like this Partner already exists, Please check the details!')
            else {
                message.success('Partner created successfully!');
                this.props.changeKey('dashboard');
            }

        })
    }


    async handleChange(value) {
        if (value === 'butcher') {
            await this.setState({
                showDownPayment: true
            })
        }
        else if (value === 'restaurant') {
            await this.setState({
                showDownPayment: false
            })
        }
    }

    render() {
        return (
            <div className="create-partner-content">
                <h2 align="center">
                    <p className="create-partner-content-title">Créer un compte partenaire !</p>
                </h2>
                <h2 align="center">
                    <p className="create-partner-content-subtitle">Devenez partenaire en quelques cliques</p>
                </h2>
                <Form
                    name="create-partner-form"
                    className="create-partner-form"
                    onFinish={this.onFinish.bind(this)}>
                    <div className="create-partner-form-flex">
                        <div>
                            <Form.Item
                                name="establishment_name" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Nom de établissement" />
                            </Form.Item>
                            <Form.Item
                                name="street" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Adresse (rue et numéro)" />
                            </Form.Item>
                            <Form.Item
                                name="city" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Ville" />
                            </Form.Item>
                            <Form.Item
                                name="zipcode" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Code postal" />
                            </Form.Item>
                            <Form.Item
                                name="phone_number" rules={[{ required: true, message: "Ceci est nécessaire", len: 10 }]}>
                                <Input placeholder="Numéro de téléphone" type="telephone" />
                            </Form.Item>
                            <Form.Item
                                name="email_admin" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Adresse email pour Mooze Admin app" type="email" />
                            </Form.Item>
                            <Form.Item
                                name="email_pro" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Adresse email pour Mooze Pro app" type="email" />
                            </Form.Item>
                            <Form.Item
                                name="establishment_rib" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="RIB" />
                            </Form.Item>
                            <Form.Item
                                name="establishment_commission" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Comission (%)" type="number" min={0} max={100} step="0.01"/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                name="partner_type" rules={[{ required: true, message: "Ceci est nécessaire" }]}
                            >
                                <Select placeholder="Type de établissement" onChange={this.handleChange.bind(this)}>
                                    <Option value="restaurant">Restaurant</Option>
                                    <Option value="butcher">Butcher</Option>
                                </Select>
                            </Form.Item>
                            {this.state.showDownPayment ?
                                <Form.Item
                                    name="down_payment" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                    <Input placeholder="Acompte pour le boucher (%)" type="number" min={0} max={100} />
                                </Form.Item>
                                : null}
                            <Form.Item>
                                <Checkbox onChange={()=> this.onSponsor()}>Sponsorisé par Mooze</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="partner_onboardingForm_button">
                                    Envoyer
                        </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export default CreatePartner;