import React, { Component } from 'react'
import { Form, Button, message, Input, Select, Checkbox } from 'antd'
import axios from 'axios';

class PartnerDetails extends Component {
    state = {
        values: {},
        sponsored: false,
    }
    constructor(props) {
        super(props);
        let url = process.env.REACT_APP_HOST + process.env.REACT_APP_GET_PARTNER_DETAILS_API
        var user_type_id = ""
        switch (this.props.partner_type) {
            case 'Restaurants':
                user_type_id = "2"
                break;
            case 'Boucheries':
                user_type_id = "3"
                break;
        }
        let axiosData = {
            params: {
                partner_id: this.props.user
            },
            headers: {
                'user_type_id': user_type_id,
            }
        }
        axios
            .get(url, axiosData)
            .catch(function (e) {
                message.error("Error while getting partner details", 5);
            })
            .then(async (res) => {
                await this.setState({ values: res.data, sponsored: res.data.is_sponsored })
            })
    }

    async onSponsor() {
        if (this.state.sponsored)
            await this.setState({
                sponsored: false,
            })
        else
            await this.setState({
                sponsored: true,
            })
        console.log(this.state.sponsored);
    }

    onFinish(values) {
        let url = process.env.REACT_APP_HOST + process.env.REACT_APP_UPDATE_PARTNER_DETAILS_API
        var user_type_id = ""
        switch (this.props.partner_type) {
            case 'Restaurants':
                user_type_id = "2"
                break;
            case 'Boucheries':
                user_type_id = "3"
                break;
        }
        let axiosHeaders = {
            headers: {
                'user_type_id': user_type_id,
            }
        }
        values.id = this.state.values._id
        values.is_sponsored = this.state.sponsored
        axios
            .post(url, values, axiosHeaders)
            .catch(function (error) {
                console.log(error)
            })
            .then(async (res) => {
                this.setState({ values: values })
                message.success("Partner details have been changed", 2)
                await window.location.reload()
            })
    }
    render() {
        if (Object.keys(this.state.values).length === 0) return null
        return (
            <div className="partner-details-form">
                <h1 align="center" className="partner-details-title">
                    Modifier les détails du partenaire
                </h1>
                <Form
                    name="partner-details-form"
                    initialValues={this.state.values}
                    className="partner-details-form"
                    onFinish={this.onFinish.bind(this)}>
                    <div className="partner-details-form-flex">
                        <div className="partner-details-form-left">
                            <Form.Item
                                labelCol={{ span: 24 }}
                                label="Nom de établissement:"
                                name="establishment_name" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Nom de établissement" />
                            </Form.Item>
                            <Form.Item label="Adresse (rue et numéro):"
                                labelCol={{ span: 24 }}
                                name="street" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Adresse (rue et numéro)" />
                            </Form.Item>
                            <Form.Item label="Ville:"
                                labelCol={{ span: 24 }}
                                name="city" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Ville" />
                            </Form.Item>
                            <Form.Item
                                label="Code postal:"
                                labelCol={{ span: 24 }}
                                name="zipcode" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Code postal" />
                            </Form.Item>
                            <Form.Item
                                label="Numéro de téléphone:"
                                labelCol={{ span: 24 }}
                                name="phone_number" rules={[{ required: true, message: "Ceci est nécessaire", len: 10 }]}>
                                <Input placeholder="Numéro de téléphone" type="telephone" />
                            </Form.Item>
                            <Form.Item
                                label="Adresse email pour Mooze Admin app:" type="email"
                                labelCol={{ span: 24 }}
                                name="email_admin" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Adresse email pour Mooze Admin app" type="email" />
                            </Form.Item>
                            <Form.Item
                                label="Adresse email pour Mooze Pro app:"
                                labelCol={{ span: 24 }}
                                name="email_pro" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Adresse email pour Mooze Pro app" type="email" />
                            </Form.Item>
                        </div>
                        <div className="partner-details-form-right">
                            <Form.Item
                                label="RIB:"
                                labelCol={{ span: 24 }}
                                name="establishment_rib" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="RIB" />
                            </Form.Item>
                            <Form.Item
                                label="Comission (%):"
                                labelCol={{ span: 24 }}
                                name="establishment_commission" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                <Input placeholder="Comission (%)" type="number" min={0} max={100} />
                            </Form.Item>
                            {this.props.partner_type === "Boucheries" ?
                                <Form.Item
                                    label="Acompte pour le boucher (%):"
                                    labelCol={{ span: 24 }}
                                    name="down_payment" rules={[{ required: true, message: "Ceci est nécessaire" }]}>
                                    <Input placeholder="Acompte pour le boucher (%)" type="number" min={0} max={100} />
                                </Form.Item>
                                : null}
                                <Form.Item>
                                <Checkbox 
                                defaultChecked={this.state.sponsored}
                                onChange={()=> this.onSponsor()}>Sponsorisé par Mooze</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="partner-details-submit-btn">
                                    Envoyer
                        </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={this.props.changeView} className="partner-details-cancel-btn">
                                    Retour
                        </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export default PartnerDetails