import React from 'react';
import styled from 'styled-components';

export default function Checkbox(props) {
  return (
    <StyledWrapper onClick={() => props.onClick(props.item._id)}>
      <StyledCircle checked={props.checked} />
      <span>{props.item.name}{' '}{props.item.smiley}</span>
    </StyledWrapper>
  );
}

const StyledCircle = styled.div`
  width: 20px;
  height: 20px;
  margin: 8px;
  border-radius: 15px;
  border: 1px solid #dadada;
  background-color: ${props => props.checked ? '#dadada' : '#fff'}
`
const StyledWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;
