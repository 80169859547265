import renderEmpty from "antd/lib/config-provider/renderEmpty";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, Input, message, Button, Image, Radio } from "antd";

const ModifyCategory = (props) => {

    const [formValues, setFormValues] = useState({});
    const [icons, setIcons] = useState([])
    const [search, setSearch] = useState("")
    const [selectedIcon, setSelectedIcon] = useState(props.selectedCategory.icon);
    useEffect(() => {
        setFormValues({
            name: props.selectedCategory.name
        });

        let url =
            process.env.REACT_APP_HOST + process.env.REACT_APP_GET_CATEGORY_ICONS_API;
        axios
            .get(url)
            .catch(function (error) {
                message.error(
                    "Erreur lors de la récupération des icônes de catégorie",
                    5
                );
            })
            .then(async (res) => {
                await setIcons(res.data);
            });
    }, [])

    const setIcon = async (icon) => {
        console.log("ICON", icon)
        await setSelectedIcon(icon.image)
    }

    const searchBar = (search) => {
        setSearch(search.target.value);
    }

    const goBack = async () => {
        await props.changePageView()
        await props.changeModifyCategory()
    }

    const modifyCategory = (values) => {
        console.log(values);
        values = {
            user_id: localStorage.getItem("mooze_admin_user"),
            category_id: props.selectedCategory.id,
            name: values.name,
            icon: selectedIcon,
        };
        let axiosHeaders = {
            headers: {
                user_type_id: localStorage.getItem("mooze_admin_user_type_id"),
            },
        };
        let url =
            process.env.REACT_APP_HOST + process.env.REACT_APP_MODIFY_CATEGORY_API;
        axios
            .post(url, values, axiosHeaders)
            .catch(function (error) {
                message.error("Erreur lors de la modification de la catégorie", 3);
            })
            .then(async (res) => {
                console.log("Category mmodified");
                var categories = props.categories;
                categories[props.selectedCategory.id] = {
                    name: values.name,
                    icon: selectedIcon,
                };
                await props.changeCategories(categories);
                await props.changePageView()
                await props.changeModifyCategory()
            });
    }
    if (Object.keys(formValues).length == 0) return null

    return (
        <div className="modify-category-page">
            <h2>
                <p className="modify-category-title">Modifier la catégorie</p>
            </h2>
            <Form
                initialValues={formValues}
                className="modify-category-form"
                name="modify-category-form"
                onFinish={modifyCategory}
            >
                <Form.Item
                    className="modify-category-name"
                    name="name"
                    rules={[{ required: true, message: "Ceci est nécessaire" }]}
                >
                    <Input placeholder="Nom de la catégorie" />
                </Form.Item>
                <h2 className="modify-category-icon-subtitle">sélectionnez une icône</h2>
                <br></br>
                <div className="modify-category-icons">
                    <Input
                        className="modify-category-search"
                        placeholder="Rechercher"
                        onChange={searchBar}
                    ></Input>
                    <div className="modify-category-icons-list">
                        <Radio.Group defaultValue={props.selectedCategory.icon}>
                            {icons.map((icon) => (
                                <div key={icon._id}>
                                    {icon.name
                                        .toLowerCase()
                                        .indexOf(search.toLowerCase()) >= 0 ? (
                                            <div

                                                className="modify-category-icons-list-icon"
                                            >
                                                <Radio value={icon.image}>
                                                    <Image
                                                        onClick={() => {
                                                            setIcon(icon);
                                                        }}
                                                        src={icon.image}
                                                        width={50}
                                                        preview={false}
                                                    ></Image>
                                                </Radio>
                                            </div>
                                        ) : null}
                                </div>
                            ))}
                        </Radio.Group>
                    </div>
                </div>
                <br></br>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="modify-category-modify-btn"
                >
                    Modifier
          </Button>
                <br></br>
                <br></br>
                <Button
                    type="primary"
                    className="modify-category-cancel-btn"
                    onClick={goBack}
                >
                    Annuler
          </Button>
            </Form>
        </div>
    )
}

export default ModifyCategory;
